<template>
  <div class="layout-page">
    <div
      :class="{
        'main-header': true,
        scrollTopBg: scrollTop > top ? true : false,
      }"
    >
      <div class="flex-row flex-left align-center">
        <span class="desc" v-if="environment == 'test'">（测试环境）</span>
        <span class="desc" v-if="environment == 'pre'">（预发布环境）</span>
        <div class="pic1 flex-col" @click="goTop">
          <img
            class="pic3"
            referrerpolicy="no-referrer"
            src="@/assets/img/logo.png"
          />
        </div>
        <span class="home-txt" id="txt-btn" @click="goTop">首页</span>
        <span class="other-txt" id="txt-btn" @click="goNews">投放指南</span>
        <span class="other-txt" id="txt-btn" @click="goScreenCooperate">屏幕合作</span>
      </div>
      <div class="flex-row flex-right justify-end align-center">
        <div
          v-if="currentPathName !== 'AddLaunchInfo'"
          class="advertising justify-align-center"
          id="primary-btn"
          @click="launch"
        >
          <img
            class="icon"
            referrerpolicy="no-referrer"
            src="@/assets/img/add_icon.png"
          />
          <span class="txt">投放广告</span>
        </div>
        <div
          class="login-btn flex-col"
          id="default-btn"
          @click="onClick_login"
          v-if="!token"
        >
          <span class="txt">注册/登录</span>
        </div>
        <el-dropdown
          v-else
          class="user-dropdown"
          id="default-btn"
          @command="handleCommand"
          :show-timeout="0"
        >
          <div class="icon-user flex-row justify-center align-center">
            <img
              class="icon"
              referrerpolicy="no-referrer"
              src="@/assets/icon/user.png"
            />
            <span class="txt">我的账户</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="memberCenter">会员中心</el-dropdown-item>
            <el-dropdown-item command="myOrder">我的订单</el-dropdown-item>
            <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="container">
      <router-view />
    </div>
    <Footer></Footer>
    <MailtoMailDialog ref="mailtoMailRef"></MailtoMailDialog>
  </div>
</template>

<script>
import bus from "@/utils/bus";
import MailtoMailDialog from "@/components/mailtoMailDialog.vue";
import Footer from "./components/footer.vue";

export default {
  components: {
    Footer,
    MailtoMailDialog,
  },
  data() {
    return {
      token: localStorage.getItem("token") || "",
      currentPath: this.$route.path,
      showAd: true,
      environment: process.env.VUE_APP_MODE,
      scrollTop: 0,
      top: 0,
    };
  },
  mounted() {
    window.onscroll = () => {
      const t = document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = t;
      const top = this.newDiscountStatus ? 50 : 86;
      this.top = top;
      if (t >= 1200) {
        bus.$emit("enableMute");
      }
    };
  },
  computed: {
    newDiscountStatus() {
      return this.$store.state.newDiscountStatus;
    },
    currentPathName() {
      return this.$route.name;
    },
    hostname() {
      return window.location.hostname;
    }
  },
  methods: {
    launch() {
      if (this.token) {
        this.$canvaUtil.onClickCreate();
      } else {
        this.$router.push("/login").catch(() => {});
      }
    },
    goTemplateCenter() {
      if (this.currentPathName !== "TemplateCenter") {
        this.$router.push("/others/templateCenter");
      }
    },
    goNews() {
      if (this.currentPathName !== "NoviceTutorial") {
        this.$router.push("/others/noviceTutorial");
      }
    },
    goProductPrice() {
      if (this.currentPathName !== "ProductPrice") {
        this.$router.push("/memberCenter/productPrice");
      }
    },
    goScreenCooperate() {
      if (this.currentPathName !== "ScreenCooperate") {
        this.$router.push("/others/screenCooperate");
      }
    },
    goTop() {
      window.location.reload();
    },
    onClick_login() {
      this.$router.push("/login").catch(() => {});
    },
    handleCommand(command) {
      if (command === "logOut") {
        this.logOut();
      }
      if (command === "memberCenter") {
        this.$router.push({ name: "Gold" });
      }
      if (command === "myOrder") {
        this.$router.push("/myOrder");
      }
    },
    logOut() {
      // localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("timestamp");
      this.$router.push("/login").catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.layout-page {
  margin: 0 auto;
  position: relative;
}
.main-header {
  height: 70rem;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 999;
  overflow-x: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .flex-left {
    width: 60%;
    padding-left: 210rem;
    .desc {
      position: absolute;
      left: 47.5%;
      top: 0;
      color: red;
    }
    .pic1 {
      height: 45rem;
      width: 182rem;
      cursor: pointer;
      .pic3 {
        width: 100%;
      }
    }
    .home-txt {
      font-size: 17rem;
      color: #252927;
      margin-left: 73rem;
      cursor: pointer;
      font-weight: 400;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .template-center-txt,
    .other-txt {
      font-size: 17rem;
      color: #252927;
      margin-left: 70rem;
      cursor: pointer;
      font-weight: 400;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .flex-right {
    width: 40%;
    height: 100%;
    padding-right: 210rem;
    .news {
      height: 100%;
      cursor: pointer;
      &:hover {
        .news-icon,
        .txt {
          color: #2bb3e3;
        }
      }
      .news-icon {
        margin-right: 10rem;
        font-size: 22rem;
        color: #242927;
      }
      .txt {
        color: rgba(36, 41, 39, 1);
        font-size: 17rem;
        cursor: pointer;
      }
    }

    .advertising {
      width: 146rem;
      height: 44rem;
      border-radius: 22rem;
      margin-left: 36rem;
      // padding: 0 30rem;
      .icon {
        width: 26rem;
        height: 26rem;
      }
      .txt {
        color: #fff;
        font-size: 17rem;
      }
    }
    .user-dropdown {
      width: 141rem;
      height: 44rem;
      border-radius: 22rem;
      border: 1rem solid #dedede;
      margin-left: 36rem;

      .icon-user {
        height: 100%;
        .icon {
          width: 22rem;
          color: #252927;
        }
        span {
          font-size: 17rem;
          color: #252927;
          margin-left: 5rem;
        }
      }
    }
    .login-btn {
      width: 141rem;
      height: 44rem;
      border-radius: 22rem;
      border: 1rem solid #dedede;
      margin-left: 36rem;
      text-align: center;
      line-height: 44rem;
      cursor: pointer;
      .txt {
        font-size: 17rem;
        color: #252927;
      }
    }
  }
}
.scrollTopBg {
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
}
.container {
  width: 100%;
  background-color: #f9f9f9;
}
</style>
