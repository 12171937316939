/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
import { post } from '@/utils/http.js';
import ApiUrl from "./api";
// 获取canva初始化数据
export function getCanvaJwt(data) {
  return post(ApiUrl.getCanvaJwt, data);
}
// 获取验证码接口
export function getVerifyCode(data) {
  return post(ApiUrl.getVerifyCode, data);
}
// 登录接口
export function login(data) {
  return post(ApiUrl.login, data);
}
// 微信网站应用扫码登陆
export function wxWebScan(data) {
  return post(ApiUrl.wxWebScan, data);
}
// 微信网站应用绑定手机号
export function wxWebBindPhone(data) {
  return post(ApiUrl.wxWebBindPhone, data);
}
// 解绑微信
export function wxWebUnBind(data) {
  return post(ApiUrl.wxWebUnBind, data);
}
// 绑定微信
export function wxWebBind(data) {
  return post(ApiUrl.wxWebBind, data);
}
// 获取自己的信息，管理员也可以调用
export function getUserInfo(data) {
  return post(ApiUrl.getUserInfo, data);
}
// 获取文件上传临时密钥
export function getUploadKeys(data) {
  return post(ApiUrl.getUploadKeys, data);
}
// 图片合成视频
export function mergeVideo(data) {
  return post(ApiUrl.mergeVideo, data);
}
// 预检 mp4 url接口
export function checkVideoUrl(data) {
  return post(ApiUrl.checkVideoUrl, data);
}
// 创建订单接口
export function createOrder(data) {
  return post(ApiUrl.createOrder, data);
}
// 支付订单（只能支付自己的订单，管理员除外）
export function payOrder(data) {
  return post(ApiUrl.payOrder, data);
}
// 取消订单（只能取消自己的订单，管理员除外）
export function cancelOrder(data) {
  return post(ApiUrl.cancelOrder, data);
}
// 删除订单（只能删除自己已取消、已结束、审核不通过的订单，管理员除外）
export function deleteOrder(data) {
  return post(ApiUrl.deleteOrder, data);
}
// 获取订单详情（只能获取自己的订单，管理员除外）
export function getOrder(data) {
  return post(ApiUrl.getOrder, data);
}
// 定时器获取订单详情
export function getOrderTimer(data) {
  return post(ApiUrl.getOrderTimer, data);
}
// 更改订单状态，管理员审核
export function changeOrderStatus(data) {
  return post(ApiUrl.changeOrderStatus, data);
}
// 列出自己的订单（只能获取自己的订单，管理员除外）
export function getListOrder(data) {
  return post(ApiUrl.getListOrder, data);
}
// 列出自己的投放广告（只能获取自己的广告，管理员除外）
export function getListAd(data) {
  return post(ApiUrl.getListAd, data);
}
// 列出已投放广告历史，也就是已经播出的（公开全局接口，所有用户的）
export function getListAdHistory(data) {
  return post(ApiUrl.getListAdHistory, data);
}
// 获取直播地址，目前仅支持一个直播
export function getLiveAddress(data) {
  return post(ApiUrl.getLiveAddress, data);
}
// 列出模板
export function getCanvaTemplateList(data) {
  return post(ApiUrl.getCanvaTemplateList, data);
}
export function getStsInfo(data) {
  return post(ApiUrl.getStsInfo, data);
}
// 获取大屏是否在营业时间段
export function getConfig(data) {
  return post(ApiUrl.getConfig, data);
}
// 获取黄金会员一折购买情况
export function getBuyTimeInfo(data) {
  return post(ApiUrl.getBuyTimeInfo, data);
}
// 购买一折时长
export function createBuyTime(data) {
  return post(ApiUrl.createBuyTime, data);
}
// 获取一折时长临时订单情况
export function getBuyTimeOrderInfo(data) {
  return post(ApiUrl.getBuyTimeOrderInfo, data);
}
// 获取时长的收入和支出详情
export function getListBusinessHistory(data) {
  return post(ApiUrl.getListBusinessHistory, data);
}
// 编辑自己的信息，管理员也可以调用，目前编辑头像和昵称
export function editUserInfo(data) {
  return post(ApiUrl.editUserInfo, data);
}
// 列出大屏，公开接口
export function getListDaping(data) {
  return post(ApiUrl.getListDaping, data);
}
// 购买普通时长
export function createCommonBuyTime(data) {
  return post(ApiUrl.createCommonBuyTime, data);
}
// 获取普通时长订单情况
export function getCommonBuyTimeOrderInfo(data) {
  return post(ApiUrl.getCommonBuyTimeOrderInfo, data);
}
// AI检测图片
export function checkPictureByAi(data) {
  return post(ApiUrl.checkPictureByAi, data);
}
// 分享订单，获取分享码
export function shareOrder(data) {
  return post(ApiUrl.shareOrder, data);
}
// 获取分享码信息
export function getShareCodeInfo(data) {
  return post(ApiUrl.getShareCodeInfo, data);
}
// 获取订单详情，分享码模式
export function getOrderByShareCode(data) {
  return post(ApiUrl.getOrderByShareCode, data);
}
// 列出投放广告，分享码模式
export function listAdByShareCode(data) {
  return post(ApiUrl.listAdByShareCode, data);
}
// 支付订单（表白套餐）
export function createPackage(data) {
  return post(ApiUrl.createPackage, data);
}
// 查询订单（支付订单（表白套餐））
export function getPackageOrderInfo(data) {
  return post(ApiUrl.getPackageOrderInfo, data);
}
// 购买黄金会员
export function buyVip(data) {
  return post(ApiUrl.buyVip, data);
}
// 获取购买黄金会员订单情况
export function getBuyVipOrderInfo(data) {
  return post(ApiUrl.getBuyVipOrderInfo, data);
}
// 列出我的视频，管理员可用
export function listMyVideo(data) {
  return post(ApiUrl.listMyVideo, data);
}
// 删除我的视频
export function deleteMyVideo(data) {
  return post(ApiUrl.deleteMyVideo, data);
}
// 删除我的设计
export function deleteMyDesignVideo(data) {
  return post(ApiUrl.deleteMyDesignVideo, data);
}
// 修改视频封面
export function updateOrderCover(data) {
  return post(ApiUrl.updateOrderCover, data);
}
// 视频是否能够免审核
export function getMp4VideoUrl(data) {
  return post(ApiUrl.getMp4VideoUrl, data);
}
// 联系我们
export function contactUs(data) {
  return post(ApiUrl.contactUs, data);
}
