<template>
  <el-popover
    v-if="currentPathName != 'CooperationEn'"
    class="popover"
    placement="left"
    trigger="hover"
    :visible-arrow="false"
    :style="'bottom:' + bottom + 'rem'"
    popper-class="homeServicePopover"
  >
    <div class="popover-content flex-col justify-between align-center">
      <span>电话：400-998-9272</span>
      <span style="margin-top: 5rem">周一至周六：9:00-20:00</span>
    </div>
    <div slot="reference" class="reference">
      <!-- <i class="el-icon-service"></i> -->
      <svg-icon class="service-svg" iconClass="service"></svg-icon>
    </div>
  </el-popover>
</template>

<script>
import bus from "@/utils/bus";

export default {
  data() {
    return {
      bottom: 150,
      normalStatus: true,
    };
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    }
  },
  mounted() {
    bus.$on("isAddLaunchInfo", (status, num) => {
      this.normalStatus = status;
      if (!status) {
        if (num) {
          this.bottom = num + 90;
        } else {
          this.bottom = 210;
        }
      } else {
        this.bottom = 150;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.popover {
  position: fixed;
  bottom: 150rem;
  right: 40rem;
  z-index: 101;

  .reference {
    width: 50rem;
    height: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s;
    background-color: #cbf0fd;
    cursor: pointer;

    i {
      font-size: 20rem;
      color: #3abbf8;
    }
    .service-svg {
      color: #42b6fb;
      width: 48rem;
      height: 48rem;
    }

    &:hover {
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);

      i {
        color: #fff;
      }
      .service-svg {
        color: #fff;
      }
    }
  }
}
</style>
