import Vue from "vue";
import moment from "moment";
import store from '../store';

// 自定义过滤器
// eslint-disable-next-line arrow-body-style
Vue.filter("dateFormat", (date) => {
  return moment(Number(date) * 1000).format("YYYY-MM-DD  HH:mm:ss"); // 可以根据自己需求设置时间格式
});

// eslint-disable-next-line arrow-body-style
Vue.filter("dateFormat2", (date) => {
  return moment(Number(date) * 1000).format("YYYY-MM-DD"); // 可以根据自己需求设置时间格式
});

Vue.filter("screenFilter", (name) => {
  let findV = '';
  findV = store.state.screenList.find(
    (v) => v.name == name
  );
  // 如果主屏信息找不到
  if (!findV) {
    let findV2 = '';
    store.state.screenList.forEach((item) => {
      if (item.attachSub.length > 0) {
        if (item.attachSub.find(
          (v) => v.name == name
        )) {
          findV2 = item.attachSub.find(
            (v) => v.name == name
          );
        }
      }
    });
    if (findV2) {
      findV = findV2;
    }
  }
  if (findV) {
    return findV.remark;
  }
  return '-';
});

// Vue.filter("screenFilter", (name) => store.state.screenList.find((v) => v.name == name).remark);
