/* eslint-disable import/no-cycle */
/* eslint-disable new-cap */
/* eslint-disable no-restricted-syntax */

// 导入 NProgress进度条 包对应的JS和CSS
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import axios from 'axios';
import qs from "qs";
import { Message, Loading } from 'element-ui';
import API from "@/api/api";
import httpCode from '../libs/httpCode';
import router from '../router';

// import { MessageBox, Message } from 'element-ui';

// axios 配置
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Grpc-Metadata-app.id': 'void',
  },
  timeout: 1800000, // request timeout 30分钟
  baseURL: '/api', // 接口请求地址
});

// 拦截重复请求
const pending = []; // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const cancelToken = axios.CancelToken;
const removeRepeatUrl = (config) => {
  const comValue = config.method === 'get' ? qs.stringify(config.params) : qs.stringify(config.data);
  for (const p in pending) {
    if (pending[p].u === `${config.url}&${config.method}&${comValue}`) { // 当前请求在数组中存在时执行函数体
      pending[p].f(); // 执行取消操作
      pending.splice(p, 1); // 把这条记录从数组中移除
    }
  }
};

const saveRepeatUrl = (config) => {
  const comValue = config.method === 'get' ? qs.stringify(config.params) : qs.stringify(config.data);
  // console.log(comValue)
  config.cancelToken = new cancelToken((c) => {
    pending.push({ u: `${config.url}&${config.method}&${comValue}`, f: c }); // 自定义唯一标识
  });
};
let loading = null;
// 添加请求拦截器
instance.interceptors.request.use((config) => {
  // console.log('请求拦截器数据', config);
  // console.log(router.history.current.name);
  // 定义需要全局加载状态的接口
  const loadingApiArr = [API.getOrder, API.getListOrder];
  if (loadingApiArr.indexOf(config.url) !== -1) {
    if (router.history.current.name != 'PayStatus') {
      loading = Loading.service({
        lock: true,
        background: "rgba(255, 255, 255, 0.8)",
      });
    }
  }
  const whiteList = [API.getLiveAddress, API.getConfig,
    API.getStsInfo, API.getOrderByShareCode, API.listAdByShareCode, API.getOrderTimer];
  if (whiteList.indexOf(config.url) == -1) {
    NProgress.start();
  }
  config.headers.authorization = `bearer ${localStorage.getItem('token')}` || 'bearer '; // 请求添加token
  removeRepeatUrl(config); // 在一个ajax发送前执行一下取消操作
  saveRepeatUrl(config); // 保存请求地址
  return config;
}, (error) => {
  // 对请求错误做些什么
  console.log(error);// for debug
  if (loading) {
    loading.close();
  }
  NProgress.done();
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use((response) => {
  removeRepeatUrl(response.config); // 执行取消操作，把已经完成的请求从pending中移除
  // 如果接口返回token，替换本地旧token
  // console.log('响应拦截器数据', response);
  // console.log(API);
  if (response.data.token && response.config.url == API.login) {
    localStorage.setItem("token", response.data.token);
  }
  // 自定义设置后台返回code对应的响应方式
  if (response.data.error.code !== '0') {
    const ErrMsg = response.data.error.msg;
    const findCode = httpCode.find((v) => v.value == response.data.error.code);
    if (findCode) {
      if (findCode.value == '21003') return Promise.reject(response.data.error.code);
      if (findCode.value == '10001' || findCode.value == '10002') {
        Message({
          message: `${findCode.label}：${ErrMsg}`,
          type: 'error',
          duration: 3000,
          center: true,
        });
      } else {
        Message({
          message: findCode.label,
          type: 'error',
          duration: 3000,
          center: true,
        });
      }
    } else {
      Message({
        message: '未知错误，请联系管理员',
        type: 'error',
        duration: 3000,
        center: true,
      });
    }
    if (loading) {
      loading.close();
    }
    NProgress.done();
    // 将错误码抛给错误回调
    return Promise.reject(response.data.error.code);
  }
  // 接口code==0，返回正常，返回数据
  if (loading) {
    loading.close();
  }
  NProgress.done();
  return response;
}, (error) => {
  // 对响应错误做点什么
  if (loading) {
    loading.close();
  }
  NProgress.done();
  // if (!error.response) {
  //   // 断网了
  //   Message({
  //     message: '网络异常',
  //     type: 'warning',
  //     duration: 3000,
  //     center: true,
  //   });
  // }
  if (error.stack && error.stack.indexOf('timeout') > -1) {
    // 请求超时
    Message({
      message: '请求超时',
      type: 'warning',
      duration: 3000,
      center: true,
    });
  }
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 400:
        error.message = "错误请求";
        break;
      case 401:
        error.message = "未授权，请重新登录";
        // localStorage.clear();
        localStorage.removeItem("token");
        localStorage.removeItem("timestamp");
        router.push("/login").catch(() => {});
        break;
      case 403:
        error.message = "拒绝访问";
        break;
      case 404:
        error.message = "请求错误，未找到该资源";
        break;
      case 405:
        error.message = "请求方法未允许";
        break;
      case 408:
        error.message = "请求超时";
        break;
      case 500:
        error.message = "服务器内部错误";
        break;
      case 502:
        error.message = "服务器异常";
        break;
      case 503:
        error.message = "服务不可用";
        break;
      case 504:
        error.message = "网关超时";
        break;
      default:
        error.message = "未知错误";
        break;
    }
    Message({
      message: error.message,
      type: 'warning',
      duration: 3000,
      center: true,
    });
  }
  return Promise.reject(error);
});

export default instance;

/**
 * post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then((response) => {
      // 对接口错误码做处理
      resolve(response.data);
    }, (err) => {
      reject(err);
    });
  });
}

/**
 * get 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装所有请求
 * @param methed
 * @param url
 * @param data
 * @param headers
 * @returns {Promise}
 */
export function request(methed, url, data = {}, headers) {
  return new Promise((resolve, reject) => {
    instance({
      method: methed || 'post',
      url,
      params: methed === 'get' ? data : '',
      data: methed !== 'get' ? data : '',
      headers: headers || { 'Content-Type': 'application/json', 'Grpc-Metadata-app.id': 'void' },
    })
      .then((response) => {
        // 对接口错误码做处理
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
