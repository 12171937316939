<template>
  <div
    :class="{
      'main-header': true,
      isLogin: currentPathName == 'Login' ? true : false,
      scrollTopBg:
        transparentTopList.includes(currentPathName) && !scrollTopType,
    }"
  >
    <div class="flex-row flex-left align-center">
      <div class="pic1 flex-col" @click="goTop">
        <img
          class="pic3"
          referrerpolicy="no-referrer"
          src="@/assets/img/logo.png"
        />
      </div>
      <span class="home-txt" id="txt-btn" @click="goTop">首页</span>
      <span class="other-txt" id="txt-btn" @click="goNews">投放指南</span>
      <span class="other-txt" id="txt-btn" @click="goScreenCooperate">屏幕合作</span>
    </div>
    <div
      class="flex-row flex-right justify-end align-center"
      :style="
        currentPathName == 'MyCanva' || currentPathName == 'MyVideo'
          ? 'padding-right: 220rem'
          : ''
      "
    >
      <div
        v-if="currentPathName !== 'AddLaunchInfo'"
        class="advertising justify-align-center"
        id="primary-btn"
        @click="launch"
      >
        <img
          class="icon"
          referrerpolicy="no-referrer"
          src="@/assets/img/add_icon.png"
        />
        <span class="txt">投放广告</span>
      </div>
      <div
        class="login-btn flex-col"
        id="default-btn"
        :style="
          transparentTopList.includes(currentPathName)
            ? 'background-color: rgba(255, 255, 255, 0);'
            : ''
        "
        @click="onClick_login"
        v-if="!token"
      >
        <span class="txt">注册/登录</span>
      </div>
      <el-dropdown
        v-else
        class="user-dropdown"
        id="default-btn"
        :style="
          transparentTopList.includes(currentPathName)
            ? 'background-color: rgba(255, 255, 255, 0);'
            : ''
        "
        @command="handleCommand"
        :show-timeout="0"
      >
        <div class="icon-user flex-row justify-center align-center">
          <img
            class="icon"
            referrerpolicy="no-referrer"
            src="@/assets/icon/user.png"
          />
          <span class="txt">我的账户</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="memberCenter">会员中心</el-dropdown-item>
          <el-dropdown-item command="myOrder">我的订单</el-dropdown-item>
          <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: localStorage.getItem("token") || "",
      scrollTopType: false,
      transparentTopList: ["TemplateCenter", "ScreenCooperate"], // 透明顶部页面
    };
  },
  mounted() {
    // console.log(this.$route.name);
    window.onscroll = () => {
      const t = document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTopType = t > 70;
    };
  },
  computed: {
    newDiscountStatus() {
      return this.$store.state.newDiscountStatus;
    },
    currentPathName() {
      return this.$route.name;
    }
  },
  methods: {
    launch() {
      if (this.token) {
        this.$canvaUtil.onClickCreate();
      } else if (this.currentPathName === "Login") {
        window.location.reload();
      } else {
        this.$router.push("/login").catch(() => {});
      }
    },
    goTemplateCenter() {
      if (this.currentPathName !== "TemplateCenter") {
        this.$router.push("/others/templateCenter");
      }
    },
    goNews() {
      if (this.currentPathName !== "NoviceTutorial") {
        this.$router.push("/others/noviceTutorial");
      }
    },
    goProductPrice() {
      if (this.currentPathName !== "ProductPrice") {
        this.$router.push("/memberCenter/productPrice");
      }
    },
    goScreenCooperate() {
      if (this.currentPathName !== "ScreenCooperate") {
        this.$router.push("/others/screenCooperate");
      }
    },
    goTop() {
      this.$router.push("/");
    },
    onClick_login() {
      if (this.currentPathName === "Login") {
        window.location.reload();
      } else {
        this.$router.push("/login").catch(() => {});
      }
    },
    handleCommand(command) {
      if (command === "logOut") {
        this.logOut();
      }
      if (command === "memberCenter") {
        if (this.currentPathName === "Gold") {
          window.location.reload();
        } else {
          this.$router.push({ name: "Gold" });
        }
      }
      if (command === "myOrder") {
        if (this.currentPathName === "MyOrderIndex") {
          window.location.reload();
        } else {
          this.$router.push("/myOrder");
        }
      }
    },
    logOut() {
      // localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("timestamp");
      this.$router.push("/login").catch(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
.main-header {
  height: 70rem;
  background-color: rgba(255, 255, 255, 1);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 999;
  overflow-x: auto;
  margin: 0 auto;
  border-bottom: 1rem solid #ebebeb;
  display: flex;
  justify-content: space-between;

  .flex-left {
    width: 60%;
    padding-left: 210rem;
    .pic1 {
      height: 45rem;
      width: 182rem;
      cursor: pointer;
      .pic3 {
        width: 100%;
      }
    }
    .home-txt {
      font-size: 17rem;
      font-weight: 400;
      color: #252927;
      margin-left: 73rem;
      cursor: pointer;
    }
    .template-center-txt,
    .other-txt {
      font-size: 17rem;
      font-weight: 400;
      color: #252927;
      margin-left: 70rem;
      cursor: pointer;
    }
  }
  .flex-right {
    width: 40%;
    height: 100%;
    padding-right: 210rem;
    .news {
      height: 100%;
      cursor: pointer;
      &:hover {
        .news-icon,
        .txt {
          color: #2bb3e3;
        }
      }
      .news-icon {
        margin-right: 10rem;
        font-size: 22rem;
        color: #242927;
      }
      .txt {
        width: 72rem;
        height: 25rem;
        display: block;
        overflow-wrap: break-word;
        color: rgba(36, 41, 39, 1);
        font-size: 17rem;
        white-space: nowrap;
        line-height: 25rem;
        text-align: center;
        cursor: pointer;
      }
    }

    .advertising {
      width: 146rem;
      height: 44rem;
      border-radius: 22rem;
      margin-left: 36rem;
      .icon {
        width: 26rem;
        height: 26rem;
      }
      .txt {
        // width: 72rem;
        height: 25rem;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 17rem;
        white-space: nowrap;
        line-height: 25rem;
        text-align: center;
      }
    }
    .user-dropdown {
      width: 141rem;
      height: 44rem;
      border-radius: 22rem;
      border: 1rem solid #dedede;
      margin-left: 36rem;

      .icon-user {
        height: 100%;
        .icon {
          width: 22rem;
          color: #252927;
        }
        span {
          font-size: 17rem;
          color: #252927;
          margin-left: 5rem;
        }
      }
    }
    .login-btn {
      width: 141rem;
      height: 44rem;
      border-radius: 22rem;
      border: 1rem solid #dedede;
      margin-left: 36rem;
      text-align: center;
      line-height: 44rem;
      z-index: 99999;
      cursor: pointer;
      .txt {
        font-size: 17rem;
        color: #252927;
      }
    }
  }
}
.scrollTopBg {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: none;
}
.isLogin {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  .flex-left {
    width: 840rem;
    background-color: #f9fafb;
  }
}
</style>
