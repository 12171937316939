<template>
  <div class="main-footer flex-col align-center">
    <div class="footer-bottom flex-col justify-center align-center">
      <div class="flex-row align-center">
        <span class="span1">© 2024 深圳市萨克森计算机有限公司</span>
        <span class="span2">大屏时代是深圳市萨克森计算机有限公司研发的一款广告自助投放软件</span>
      </div>
      <div class="flex-row align-center" style="margin-top: 4rem;">
        <span class="span1">广播电视节目制作经营许可证：(粤)字第50633号</span>
        <span class="span2">增值电信业务经营许可证：粤B2-20181556</span>
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备14024433号</a>
      </div>
      <div class="police-view flex-row align-center">
        <img src="@/assets/img/police.png" alt="" />
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009354"
          target="_blank"
          >粤公网安备 44030502009354号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.main-footer {
  width: 100%;
  height: 100rem;
  // background-color: #f5f5f5;
  background-color: #fbfdff;
  z-index: 99;
  .footer-bottom {
    width: 1280rem;
    height: 99rem;
    border-top: 1px solid #acadad;
    .span1 {
      display: block;
      overflow-wrap: break-word;
      color: rgba(106, 106, 106, 1);
      font-size: 16rem;
      white-space: nowrap;
      text-align: center;
      margin-right: 18rem;
    }
    .span2 {
      display: block;
      overflow-wrap: break-word;
      color: rgba(106, 106, 106, 1);
      font-size: 16rem;
      white-space: nowrap;
      text-align: center;
      margin-right: 18rem;
    }
    a {
      display: block;
      overflow-wrap: break-word;
      color: rgba(106, 106, 106, 1);
      font-size: 16rem;
      white-space: nowrap;
      text-align: center;
      text-decoration: none;
      &:hover {
        color: #000;
      }
    }
    .police-view {
      margin-top: 4rem;
      img {
        width: 14rem;
        margin-right: 4rem;
      }
    }
  }
}
</style>
