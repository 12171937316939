<template>
  <el-dialog
    title=""
    :visible.sync="cannotUseCanvaVisible"
    width="480rem"
    class="dialog"
  >
    <div class="dialog_box flex-col">
      <span class="dialog_txt">因成本原因，仅购买企业套餐的用户可使用在线模板功能</span>
      <div slot="footer" class="dialog-footer justify-center">
        <div @click="goProductPrice">了解更多</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import bus from "@/utils/bus";

export default {
  data() {
    return {
      cannotUseCanvaVisible: false,
    };
  },
  created() {},
  computed: {},
  mounted() {
    bus.$on("openCannotUseCanvaDialog", () => {
      this.cannotUseCanvaVisible = true;
    });
  },
  methods: {
    goProductPrice() {
      this.cannotUseCanvaVisible = false;
      this.$router.push({ name: "ProductPrice" });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .dialog_box {
    .dialog_txt {
      font-size: 18rem;
      color: #252927;
      line-height: 30rem;
      text-align: center;
    }
    .dialog-footer {
      width: 100%;
      margin-top: 13%;
      div {
        width: 158rem;
        height: 44rem;
        line-height: 44rem;
        border-radius: 25rem;
        text-align: center;
        font-size: 16rem;
        color: #2bb3e3;
        background: #ffffff;
        border-radius: 25rem;
        border: 1rem solid #32c3f6;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
          color: #fff;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 12.5% 20% 10% 20%;
}
</style>
