/* 路由映射 */
export default {
  login: () => import('@/views/login/index.vue'),
  demoLogin: () => import('@/views/login/demoLogin.vue'),
  index: () => import('@/views/home/index.vue'),
  addLaunchInfo: () => import('@/views/launchAd/addLaunchInfo.vue'),
  payOrder: () => import('@/views/launchAd/payOrder.vue'),
  payStatus: () => import('@/views/launchAd/payStatus.vue'),
  myOrderIndex: () => import('@/views/myOrder/index.vue'),
  myOrderDetail: () => import('@/views/myOrder/detail.vue'),
  myVideo: () => import('@/views/myOrder/myVideo.vue'),
  myCanva: () => import('@/views/myOrder/myCanva.vue'),
  noviceTutorial: () => import('@/views/others/noviceTutorial.vue'),
  screenCooperate: () => import('@/views/others/screenCooperate.vue'),
  serviceAgreement: () => import('@/views/others/serviceAgreement.vue'),
  launch: () => import('@/views/others/launch.vue'),
  templateCenter: () => import('@/views/others/templateCenter.vue'),
  publicWelfareCooperation: () => import('@/views/others/publicWelfareCooperation.vue'),
  listAdHistory: () => import('@/views/others/listAdHistory.vue'),
  share: () => import('@/views/others/share.vue'),
  cooperation: () => import('@/views/others/cooperation.vue'),
  cooperationEn: () => import('@/views/others/cooperationEn.vue'),
  newPeopleDiscounts: () => import('@/views/others/newPeopleDiscounts.vue'),
  gold: () => import('@/views/memberCenter/gold.vue'),
  payDuration: () => import('@/views/memberCenter/payDuration.vue'),
  editInfo: () => import('@/views/memberCenter/editInfo.vue'),
  durationTable: () => import('@/views/memberCenter/durationTable.vue'),
  memCenPayStatus: () => import('@/views/memberCenter/payStatus.vue'),
  memCenBcPayStatus: () => import('@/views/memberCenter/commonPayStatus.vue'),
  productPrice: () => import('@/views/memberCenter/productPrice.vue'),
  buyPackagePayStatus: () => import('@/views/memberCenter/buyPackagePayStatus.vue'),
  buyVipPayStatus: () => import('@/views/memberCenter/buyVipPayStatus.vue'),
  error: () => import('@/views/404.vue'),
};
