<template>
  <el-dialog
    title=""
    :visible.sync="localUpErrVisible"
    width="650rem"
    :show-close="false"
    class="dialog"
    top="20vh"
  >
    <div class="dialog_box flex-col">
      <div class="box-header flex-row align-center">
        <img src="../assets/img/close-circle.png" alt="" />
        <span>文件上传失败</span>
      </div>
      <span class="dialog_txt">请上传符合以下条件的视频广告文件：</span>
      <span class="dialog_txt">1、视频分辨率的宽高比在 1.3 至 1.78 之间；</span>
      <span class="dialog_txt">2、视频时长应在 5秒 至 5分钟 之间。</span>
      <span class="dialog_txt" style="margin-top: 40rem;">对此给您带来的不便，我们深感抱歉！</span>
      <div class="dialog_txt flex-row align-center">
        如需帮助，请
        <txtServicePopover placement="bottom"/>
      </div>
      <div slot="footer" class="dialog-footer flex-row justify-center">
        <div @click="replace" id="primary-btn">换个文件试试</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import txtServicePopover from "./txtServicePopover.vue";

export default {
  components: {
    txtServicePopover,
  },
  data() {
    return {
      localUpErrVisible: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    // 打开弹窗
    openLocalUpErr() {
      this.localUpErrVisible = true;
    },
    replace() {
      this.localUpErrVisible = false;
      this.$emit("localReplace");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .dialog_box {
    .box-header {
      margin-bottom: 52rem;
      img {
        width: 56rem;
      }
      span {
        font-size: 24rem;
        color: #252927;
        line-height: 33rem;
        margin-left: 24rem;
      }
    }
    .dialog_txt {
      font-size: 18rem;
      color: #252927;
      line-height: 29rem;
      text-align: left;
      .u {
        cursor: pointer;
        color: #2bb3e3;
      }
    }
    .dialog-footer {
      width: 100%;
      margin-top: 78rem;
      div {
        // width: 144rem;
        // height: 42rem;
        // line-height: 42rem;
        border-radius: 25rem;
        text-align: center;
        padding: 3% 17%;
        color: #fff;
        font-size: 16rem;
        // background: #ffffff;
        // border-radius: 25rem;
        // border: 1rem solid #32c3f6;
        // cursor: pointer;
        // transition: all 0.3s;
        // &:hover {
        //   background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        //   color: #fff;
        // }
      }
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 12.5% 12% 12% 12%;
}
</style>
