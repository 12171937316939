<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogStatus"
      class="dialog_box"
      width="1280rem"
    >
      <div v-if="currentTem" class="flex-row justify-between">
        <div class="video-view flex-col">
          <div class="flex-row align-center">
            <i class="el-icon-arrow-left" @click="dialogPrev"></i>
            <video
              ref="videoRef"
              :src="currentTem.url"
              controls
              autoplay
              class="video"
              style="object-fit: contain"
            ></video>
            <i class="el-icon-arrow-right" @click="dialogLower"></i>
          </div>
          <span class="title">{{ currentTem.title }}</span>
          <div
            v-if="isBuyVip"
            class="launch-ad"
            id="primary-btn"
            @click="onClick_edit"
          >
            投放同款广告
          </div>
          <div
            v-else
            class="launch-ad"
            id="primary-btn"
            style="background: rgba(0,0,0,0.1);cursor: not-allowed;"
          >
            投放同款广告
          </div>
          <span
            v-if="!isBuyVip"
            class="mode-text"
            >仅企业套餐用户可用
            <span class="txt-btn" @click="goProductPrice">去升级</span></span
          >
        </div>
        <div class="launch-notice flex-col">
          <div class="dialog_title flex-col">
            <span>广告投放流程</span>
            <div></div>
          </div>
          <div class="flex-col process-list">
            <div
              v-for="(process, index) in processList"
              :key="index"
              class="process flex-col"
            >
              <div class="process-title flex-row align-center">
                <div>
                  {{ index + 1 }}
                </div>
                <span>{{ process.title }}</span>
              </div>
              <div class="process-tips" v-if="process.tips2">
                {{ process.tips2 }}
                <div
                  class="btn-tip"
                  @click="$refs.upload.$children[0].handleClick()"
                >
                  "视频文件投放广告"
                </div>
              </div>
              <div
                class="process-tips"
                style="margin-top: 8rem"
                v-if="process.tips3"
              >
                {{ process.tips3 }}
                <div
                  class="btn-tip"
                  @click="$refs.refUploadLocalImg.openDialog()"
                >
                  "图片文件投放广告"
                </div>
              </div>
              <span
                class="process-tip"
                style="margin-top: 8rem"
                v-if="process.tips"
                >{{ process.tips }}</span
              >
              <span class="process-payType" v-if="process.payType">{{
                process.payType
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-upload
        ref="upload"
        :show-file-list="false"
        class="upload-demo"
        action="action"
        accept=".mp4"
        :auto-upload="false"
        :on-change="changeFile"
      >
      </el-upload>
    </el-dialog>
    <localUploadErr
      ref="refLocalUploadErr"
      @localReplace="$refs.upload.$children[0].handleClick()"
      @closeDialogStatus="dialogStatus = false"
    ></localUploadErr>
    <uploadLocalImgDialog
      ref="refUploadLocalImg"
      @closeDialogStatus="dialogStatus = false"
    ></uploadLocalImgDialog>
    <el-dialog
      title=""
      :visible.sync="reLaunchStatus"
      width="698rem"
      :show-close="false"
      custom-class="newReLaunch"
    >
      <div id="reLaunchDialog" class="flex-col align-center">
        <p id="reLaunchP">确定是使用这个视频投放广告吗？</p>
        <div slot="footer" class="dialog-footer flex-row">
          <div id="default-btn" @click="reLaunchStatus = false">取消</div>
          <div id="primary-btn" @click="onOkReLaunch">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/utils/bus";
import BMF from "browser-md5-file";
import { getAliToken, ossUpload } from "../utils/oss";
import { checkVideoUrl, getCanvaTemplateList } from "../api/request";
import localUploadErr from "./localUploadErr.vue";
import uploadLocalImgDialog from "./uploadLocalImgDialog.vue";

const bmf = new BMF();

export default {
  components: {
    localUploadErr,
    uploadLocalImgDialog,
  },
  props: {},
  data() {
    return {
      dialogStatus: false,
      reLaunchStatus: false,
      templateId: "",
      launchData: {}, // 投放数据
      processList: [
        {
          title: "编辑需要投放的广告的视频或图片",
          tips: "*如果您还没有可用的广告，可通过“在线视频模版”制作广告",
          tips2: "*如果您已备好视频广告，推荐您使用本地",
          tips3: "*如果您已备好图片广告，推荐您使用本地",
        },
        {
          title: "填写投放次数、投放时间等信息",
        },
        {
          title: "完成订单支付",
          tips: "*计费单价：每秒1元",
          payType: "*支付方式：微信、支付宝",
        },
        {
          title: "等待客服通过广告的审核",
          tips: "*工作人员会在次日大屏开机时间之前完成审核",
        },
        {
          title: "等待系统投放广告到大屏",
        },
      ],
      // 上传oss
      region: "oss-cn-beijing",
      dataObj: {},
      uploadOssAddress: "",
      imgOssAddress: "",
      url: "",
      upLoadFile: "",
      templateList: [],
      currentTem: {},
      currentTemIndex: {},
      currentVideoSize: 0, // 上传的视频大小
    };
  },
  created() {},
  mounted() {
    bus.$on("openTenCenterLaunchDialog", (templateId) => {
      this.templateId = templateId;
      this.getCanvaTemplateList();
    });
    bus.$on("closeTenCenterLaunchDialog", () => {
      this.dialogStatus = false;
    });
  },
  watch: {
    reLaunchStatus(val) {
      if (!val) {
        this.closeReLaunchStatus();
      }
    },
    dialogStatus(val) {
      const videoElement = this.$refs.videoRef;
      if (!val && videoElement) {
        videoElement.pause();
      }
    },
  },
  computed: {
    isBuyVip() {
      return this.$store.state.userInfo.businessInfo.isBuyVip;
    }
  },
  methods: {
    // 打开弹窗
    openDialog() {
      bus.$emit("enableMute"); // 关闭首页直播声音
      this.dialogStatus = true;
      this.$nextTick(() => {
        const videoElement = this.$refs.videoRef;
        videoElement.load();
      });
    },
    onClick_edit() {
      this.dialogStatus = false;
      this.$canvaUtil.onClickEdit(this.currentTem.template, true);
    },
    closeReLaunchStatus() {
      const reLaunchDialog = document.getElementById("reLaunchDialog");
      const ElementVideo = document.getElementById("reLaunchVideo");
      if (!ElementVideo.paused) {
        ElementVideo.pause();
      }
      reLaunchDialog.removeChild(ElementVideo);
      this.reLaunchStatus = false;
    },
    // 获取模板中心数据
    getCanvaTemplateList() {
      getCanvaTemplateList({}).then((res) => {
        this.templateList = res.templateList;
        this.currentTem = this.templateList.find(
          (v) => v.template == this.templateId
        );
        this.currentTemIndex = this.templateList.findIndex(
          (v) => v.template == this.templateId
        );
        if (!this.currentTem) {
          this.$message({
            type: "error",
            center: true,
            message: "数据异常，请使用其他模板",
          });
          bus.$emit("refreshTemplateList");
        } else {
          this.openDialog();
        }
      });
    },
    // 点击上一个
    dialogPrev() {
      let prevIndex = null;
      if (this.currentTemIndex == 0) {
        prevIndex = this.templateList.length - 1;
      } else {
        prevIndex = this.currentTemIndex - 1;
      }
      this.currentTem = this.templateList[prevIndex];
      this.currentTemIndex = prevIndex;
    },
    // 点击下一个
    dialogLower() {
      let lowerIndex = null;
      if (this.currentTemIndex == this.templateList.length - 1) {
        lowerIndex = 0;
      } else {
        lowerIndex = this.currentTemIndex + 1;
      }
      this.currentTem = this.templateList[lowerIndex];
      this.currentTemIndex = lowerIndex;
    },
    onOkReLaunch() {
      this.reLaunchStatus = false;
      bus.$emit("openLoadingDialog", {
        num: this.currentVideoSize,
        type: "local",
      });
      bmf.md5(
        this.upLoadFile,
        (err, md5) => {
          // console.log("err:", err);
          // console.log("md5 string:", md5);
          if (md5) {
            checkVideoUrl({
              real_hash: md5,
            })
              .then((res) => {
                // 视频上传过，无需再上传
                if (res.existHash) {
                  bus.$emit("closeLoadingDialog");
                  setTimeout(() => {
                    this.dialogStatus = false;
                    this.$router.push({
                      name: "AddLaunchInfo",
                      query: {
                        videoUrl: res.newVideoUrl,
                        realVideoDuration: res.videoDuration,
                      },
                    });
                  }, 300);
                } else {
                  this.beforeUpload(this.upLoadFile);
                }
              })
              .catch(() => {
                bus.$emit("closeLoadingDialog");
              });
          } else {
            this.beforeUpload(this.upLoadFile);
          }
        },
        // eslint-disable-next-line no-unused-vars
        (progress) => {
          // console.log("progress number:", progress);
        }
      );
    },
    //  文件状态改变时的回调函数，添加文件、上传成功和上传失败时都会被调用
    changeFile(file) {
      console.log(file);
      this.upLoadFile = file.raw;
      const size = file.raw.size / 1024 / 1024; // 上传文件的大小  MB
      this.currentVideoSize = Math.round(size);
      if (parseFloat(size) > 2048) {
        this.$message({
          type: "error",
          center: true,
          message: "请上传小于2G的视频",
        });
        return;
      }
      if (["mp4"].indexOf(file.raw.name.split(".").pop().toLowerCase()) == -1) {
        this.$message({
          type: "error",
          center: true,
          message: "请上传mp4格式的视频",
        });
        return;
      }
      // 渲染本地视频dom
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file.raw);
      videoElement.style = "max-width: 100%;max-height: 600rem";
      videoElement.id = "reLaunchVideo";
      videoElement.controls = true;
      videoElement.autoplay = true;
      videoElement.onerror = () => {
        this.$message({
          type: "error",
          center: true,
          message: "视频解析错误",
        });
      };
      videoElement.addEventListener("loadedmetadata", () => {
        console.log(
          "视频时长:",
          videoElement.duration,
          "视频宽:",
          videoElement.videoWidth,
          "视频高:",
          videoElement.videoHeight
        );
        // 读取不到视频的尺寸
        if (videoElement.videoWidth == 0 || videoElement.videoHeight == 0) {
          this.$message({
            type: "error",
            center: true,
            message: "视频尺寸解析错误",
          });
          if (videoElement) {
            videoElement.pause();
          }
          return;
        }
        // 视频时长不在5s-5分钟之间（考虑投放视频时，时长四舍五入的情况）
        if (videoElement.duration < 5 || videoElement.duration >= 300.5) {
          if (videoElement) {
            videoElement.pause();
          }
          this.$refs.refLocalUploadErr.openLocalUpErr();
          return;
        }
        // 视频尺寸（宽高比）不在1.3-1.78之间
        if (
          videoElement.videoWidth / videoElement.videoHeight < 1.3 ||
          videoElement.videoWidth / videoElement.videoHeight > 1.78
        ) {
          if (videoElement) {
            videoElement.pause();
          }
          this.$refs.refLocalUploadErr.openLocalUpErr();
          return;
        }
        if (videoElement.duration) {
          // 把视频插入dom
          this.reLaunchStatus = true;
          this.$nextTick(() => {
            const reLaunchDialog = document.getElementById("reLaunchDialog");
            const ElementsByIDP = document.getElementById("reLaunchP");
            reLaunchDialog.insertBefore(videoElement, ElementsByIDP);
            // console.log(reLaunchDialog, videoElement);
          });
        }
      });
    },
    // 上传之前获取sts 虚拟授权
    beforeUpload(file) {
      // console.log(file);
      const that = this;
      return new Promise((resolve, reject) => {
        // 获取sts 的 token
        getAliToken(that, file.name)
          .then((response) => {
            // console.log(response);
            if (response) {
              resolve(response);
              this.handleHttpRequest(file);
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async handleHttpRequest(file) {
      // console.log("file :>> ", file);
      // 上传OSS
      try {
        // 上传视频    this.dataObj为new OSS实例化的参数，上传的oss路径拼接this.uploadOssAddress
        await ossUpload(file, this.dataObj, this.uploadOssAddress, (res) => {
          const {
            res: { statusCode, requestUrls },
          } = res; // 返回状态和 url
          if (statusCode == 200) {
            const url = requestUrls[0].split("?")[0];
            this.url = url;
            // console.log(this.url);
            this.checkVideoUrl();
          }
        });
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    checkVideoUrl() {
      checkVideoUrl({
        video_url: this.url,
      })
        .then((res) => {
          bus.$emit("closeLoadingDialog");
          setTimeout(() => {
            this.dialogStatus = false;
            this.$router.push({
              name: "AddLaunchInfo",
              query: {
                videoUrl: res.newVideoUrl,
                realVideoDuration: res.videoDuration,
              },
            });
          }, 300);
        })
        .catch(() => {
          bus.$emit("closeLoadingDialog");
          // loading.close();
        });
    },
    goProductPrice() {
      this.$router.push({
        name: "ProductPrice",
        query: {
          active: 2,
        },
      });
      this.dialogStatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__body {
  padding: 60rem 77rem 60rem 50rem;
}
::v-deep .newReLaunch {
  .el-dialog__body {
    padding: 60rem 60rem 48rem;
  }
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 24rem;
}
::v-deep .el-dialog__header {
  padding: 0;
}
#reLaunchDialog {
  // padding: 0 60rem;
  p {
    margin: 30rem 0;
    font-size: 16rem;
    color: #252927;
  }
  .dialog-footer {
    div:nth-child(1) {
      width: 152rem;
      height: 46rem;
      background: #ffffff;
      border-radius: 25rem;
      border: 1rem solid #32c3f6;
      text-align: center;
      line-height: 46rem;
      color: #2bb3e3;
      &:hover {
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        color: #fff;
      }
    }
    div:nth-child(2) {
      width: 152rem;
      height: 46rem;
      border-radius: 25rem;
      text-align: center;
      line-height: 46rem;
      color: #fff;
      margin-left: 44rem;
    }
  }
}
.video-view {
  width: 704rem;
  height: 547rem;
  position: relative;
  .el-icon-arrow-left {
    margin-right: 20rem;
    color: #252927;
    font-size: 28rem;
    cursor: pointer;
  }
  .el-icon-arrow-right {
    margin-left: 20rem;
    color: #252927;
    font-size: 28rem;
    cursor: pointer;
  }
  .video {
    width: 608rem;
  }
  .title {
    font-size: 18rem;
    color: #252927;
    line-height: 25rem;
    margin-top: 26rem;
    margin-left: 48rem;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //用省略号显示
    white-space: nowrap; //不换行
  }
  .launch-ad {
    width: 188rem;
    height: 46rem;
    background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
    border-radius: 25rem;
    text-align: center;
    line-height: 46rem;
    font-size: 18rem;
    font-weight: 500;
    color: #ffffff;
    margin-top: 32rem;
    margin-left: 48rem;
  }
  .mode-text {
    margin-top: 24rem;
    margin-left: 48rem;
    font-size: 20rem;
    color: #252927;
    .txt-btn {
      cursor: pointer;
      color: #2bb3e3;
    }
  }
}
.launch-notice {
  width: 403rem;
  // height: 547rem;
  padding: 32rem 32rem 0 32rem;
  background-color: #fff;
  border-radius: 6rem;
  .dialog_title {
    width: 100%;
    span {
      font-size: 22rem;
      color: #252927;
      line-height: 30rem;
    }
    div {
      width: 52rem;
      height: 3rem;
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      position: relative;
      top: 10rem;
      margin-bottom: 36rem;
    }
  }
  .process-list {
    position: relative;
    // right: 16%;
  }
  .process {
    margin-bottom: 5%;
    .process-title {
      margin-bottom: 8rem;
      div {
        width: 23rem;
        height: 23rem;
        border-radius: 50%;
        background: #ececec;
        text-align: center;
        line-height: 23rem;
        font-size: 16rem;
        color: #252927;
      }
      span {
        font-size: 18rem;
        color: #252927;
        margin-left: 16rem;
        line-height: 25rem;
      }
    }
    .process-tip,
    .process-tips {
      font-size: 16rem;
      color: #9b9d9b;
      line-height: 24rem;
      margin-left: 39rem;
      .btn-tip {
        display: inline-block;
        color: #2bb3e3;
        border-bottom: 1rem #2bb3e3 solid;
        cursor: pointer;
      }
    }
    .process-payType {
      font-size: 16rem;
      color: #9b9d9b;
      line-height: 24rem;
      margin-left: 39rem;
      margin-top: 8rem;
    }
  }
  .el-checkbox {
    margin: 7% 0 5% 0;
  }
  .edit-ad,
  .local-ad {
    width: 250rem;
    height: 40rem;
    border-radius: 26rem;
    text-align: center;
    line-height: 40rem;
    font-size: 16rem;
    color: #fff;
    margin: 3% 0 5% 0;
  }
  .create-ad {
    width: 250rem;
    height: 40rem;
    border-radius: 26rem;
    text-align: center;
    line-height: 40rem;
    font-size: 16rem;
    color: #fff;
  }
}
</style>
