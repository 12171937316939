<template>
  <div>
    <el-dialog
      :visible.sync="uploadImgDialogSync"
      width="698rem"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="uploadCustom"
    >
      <div class="reLaunchImgDialog flex-col align-center">
        <span class="dialog-title" v-if="previewSrcList.length"
          >确定使用这{{ previewSrcList.length }}张图片投放广告吗？</span
        >
        <span class="dialog-title" v-else>请上传广告图片</span>
        <div class="uploadImg-view flex-col">
          <div class="ossImgList flex-row justify-between">
            <div
              v-for="(item, index) in ossImgList"
              :key="index"
              class="ossImgItem"
            >
              <img
                v-if="item.imgUrl"
                @click.stop="deleteOssImg(index)"
                class="icon-close"
                src="@/assets/img/close.png"
                alt=""
              />
              <el-image
                v-if="item.imgUrl"
                class="image"
                :src="item.imgUrl"
                fit="contain"
                :preview-src-list="previewSrcList"
              >
              </el-image>
              <div
                v-if="!item.imgUrl"
                @click.stop="uploadOssImg(index)"
                class="upload-view flex-col justify-center align-center"
              >
                <img src="@/assets/img/add.png" alt="" />
                <span>添加图片</span>
              </div>
              <div
                v-if="item.showLoading"
                class="upload-view-loading flex-row justify-center align-center"
              >
                <img src="@/assets/img/loading.gif" alt="" />
                <span class="loading-txt">{{ loadingNum }}%</span>
              </div>
            </div>
          </div>
          <span class="desc1">· 图片分辨率的宽高比在 1.3 至 1.78 之间</span>
          <span class="desc2"
            >· 支持jpg、jpeg、png格式，最多三张，每张不超过10MB</span
          >
          <span class="desc3"
            >· 图片将合成一个视频，视频内图片按顺序进行轮播</span
          >
        </div>
        <div class="videoDuration-view flex-col">
          <div class="flex-row align-center">
            <div class="border-left"></div>
            <span class="label">每张图片展示时长：</span>
            <el-input-number
              v-model="videoDuration"
              :precision="0"
              :max="20"
              :min="5"
            />
            <span class="unit">秒</span>
          </div>
          <span class="desc">*时长范围为5～20秒</span>
        </div>
        <div class="allVideoDuration-view flex-row align-center">
          <div class="border-left"></div>
          <span class="txt1">合成视频时长：</span>
          <span class="txt2"
            >{{ videoDuration * previewSrcList.length }}秒</span
          >
        </div>
        <div slot="footer" class="dialog-footer flex-row">
          <div id="default-btn" @click="uploadImgDialogSync = false">取消</div>
          <div
            id="primary-btn"
            @click="compositePicture"
            v-if="previewSrcList.length"
          >
            确定
          </div>
          <div id="primary-btn" class="disabled-btn" v-else>确定</div>
        </div>
      </div>
      <el-upload
        ref="upload"
        :show-file-list="false"
        action="action"
        accept=".png,.jpg,.jpeg"
        :auto-upload="false"
        :on-change="localImgChangeFile"
      >
      </el-upload>
    </el-dialog>
    <el-dialog
      :visible.sync="determineDialogSync"
      width="698rem"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="determineCustom"
    >
      <div class="determineDialog flex-col align-center">
        <video
          ref="videoRef"
          :src="newVideoUrl"
          controls
          autoplay
          class="video"
          style="object-fit: contain"
        ></video>
        <span class="dialog-title">广告视频已生成，请确认</span>
        <!-- <span class="newVideoDuration">视频时长：{{ newVideoDuration }}秒</span> -->
        <div slot="footer" class="dialog-footer flex-row">
          <div id="default-btn" @click="determineDialogSync = false">取消</div>
          <div id="primary-btn" @click="onOkReLaunch">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/utils/bus";
import { getAliToken, ossUploadImg } from "../utils/oss";
import { mergeVideo, checkVideoUrl } from "../api/request";

export default {
  data() {
    return {
      uploadImgDialogSync: false,
      determineDialogSync: false,
      currentImgIndex: null,
      countDownTimer: null,
      videoDuration: 5,
      newVideoUrl: "", // 合成成功视频的路径
      newVideoDuration: "", // 合成成功视频的时长
      loadingNum: 1,
      ossImgList: [
        {
          imgUrl: "",
          imgSize: 0,
          imgRatio: 0,
          showLoading: false,
        },
        {
          imgUrl: "",
          imgSize: 0,
          imgRatio: 0,
          showLoading: false,
        },
        {
          imgUrl: "",
          imgSize: 0,
          imgRatio: 0,
          showLoading: false,
        },
      ],
    };
  },
  mounted() {
    // bus监听断网状态
    bus.$on("OfflineStatus", () => {
      this.ossImgList[this.currentImgIndex].showLoading = false;
    });
  },
  computed: {
    previewSrcList() {
      const newArr = [];
      this.ossImgList.forEach((item) => {
        if (item.imgUrl) {
          newArr.push(item.imgUrl);
        }
      });
      return newArr;
    },
    uploadImgAllSize() {
      let size = 0;
      this.ossImgList.forEach((item) => {
        size += item.imgSize;
      });
      return size.toFixed(1);
    },
  },
  watch: {
    uploadImgDialogSync(val) {
      if (!val) {
        this.ossImgList = [
          {
            imgUrl: "",
            imgSize: 0,
            imgRatio: 0,
            showLoading: false,
          },
          {
            imgUrl: "",
            imgSize: 0,
            imgRatio: 0,
            showLoading: false,
          },
          {
            imgUrl: "",
            imgSize: 0,
            imgRatio: 0,
            showLoading: false,
          },
        ];
        this.videoDuration = 5;
      }
    },
  },
  methods: {
    openDialog() {
      this.uploadImgDialogSync = true;
    },
    uploadOssImg(index) {
      const isLoading = this.ossImgList.find((v) => v.showLoading);
      if (isLoading) return;
      this.currentImgIndex = index;
      this.$refs.upload.$children[0].handleClick();
    },
    deleteOssImg(index) {
      this.ossImgList[index].imgUrl = "";
      this.ossImgList[index].imgSize = 0;
      this.ossImgList[index].imgRatio = 0;
    },
    //  本地图片文件状态改变时的回调函数，添加文件、上传成功和上传失败时都会被调用
    localImgChangeFile(file) {
      if (!this.isOnLine()) return;
      // console.log(file);
      const size = file.raw.size / 1024 / 1024; // 上传文件的大小（MB）
      console.log(size, "size");
      this.ossImgList[this.currentImgIndex].imgSize = size;
      if (
        ["png", "jpg", "jpeg"].indexOf(
          file.raw.name.split(".").pop().toLowerCase()
        ) == -1
      ) {
        console.log(
          `图片后缀格式：${file.raw.name.split(".").pop().toLowerCase()}`
        );
        this.$message({
          type: "error",
          center: true,
          message: "请上传png/jpg/jpeg格式的图片",
        });
        return;
      }
      if (parseFloat(size) > 10) {
        console.log(`图片大小：${size}（MB）`);
        this.$message({
          type: "error",
          center: true,
          message: "请上传小于10MB的图片",
        });
        return;
      }
      // 渲染本地图片dom
      const img = new Image();
      img.onload = () => {
        const { width } = img;
        const { height } = img;
        console.log(`图片分辨率的宽高比：${width / height}`);
        if (width / height < 1.3 || width / height > 1.78) {
          this.$message({
            type: "error",
            center: true,
            message: "图片分辨率的宽高比应在 1.3 至 1.78 之间",
          });
        } else {
          if (!this.imgRatioPass(width / height)) {
            this.$message({
              type: "warning",
              center: true,
              message: "为保证合成视频效果，上传的图片分辨率不能差距过大，宽高比差额不能超过0.5。",
            });
            return;
          }
          this.ossImgList[this.currentImgIndex].imgRatio = width / height;
          this.ossImgList[this.currentImgIndex].showLoading = true;
          this.loadingNumTimer(size);
          this.beforeUpload(file.raw, true);
        }
      };
      img.οnerrοr = () => {
        this.$message({
          type: "error",
          center: true,
          message: "图片解析错误",
        });
      };
      img.src = window.URL.createObjectURL(file.raw);
      img.style = "max-width: 100%";
    },
    imgRatioPass(ratio) {
      let status = true;
      this.ossImgList.forEach((item) => {
        if (item.imgRatio != 0 && item.imgRatio != ratio && Math.abs(item.imgRatio - ratio) > 0.5) {
          status = false;
        }
      });
      return status;
    },
    // 上传之前获取sts 虚拟授权
    beforeUpload(file) {
      // console.log(file);
      const that = this;
      return new Promise((resolve, reject) => {
        // 获取sts 的 token
        getAliToken(that, file.name)
          .then((response) => {
            // console.log(response);
            if (response) {
              resolve(response);
              this.handleHttpRequestImg(file);
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            console.log(err);
            this.ossImgList[this.currentImgIndex].showLoading = false;
            reject(err);
          });
      });
    },
    // 图片上传OSS
    async handleHttpRequestImg(file) {
      try {
        // 上传视频    this.dataObj为new OSS实例化的参数，上传的oss路径拼接this.uploadOssAddress
        await ossUploadImg(file, this.dataObj, this.uploadOssAddress, (res) => {
          const {
            res: { statusCode, requestUrls },
          } = res; // 返回状态和 url
          if (statusCode == 200) {
            const url = requestUrls[0].split("?")[0];
            this.ossImgList[this.currentImgIndex].imgUrl = url;
            // console.log(this.ossImgList);
            this.loadingNum = 100;
            setTimeout(() => {
              this.ossImgList[this.currentImgIndex].showLoading = false;
            }, 200);
          }
        });
      } catch (error) {
        console.log("error :>> ", error);
        this.ossImgList[this.currentImgIndex].showLoading = false;
      }
    },
    // 模拟图片上传进度
    loadingNumTimer(num) {
      this.loadingNum = 1;
      let time = 1000; // 初始化定时器速度
      if (num >= 0 && num < 2) {
        time = 1;
      } else if (num >= 2 && num <= 5) {
        time = 5;
      }
      this.countDownTimer = setInterval(() => {
        if (this.loadingNum == 98 || this.loadingNum == 100) {
          clearInterval(this.countDownTimer);
          this.countDownTimer = null;
          return;
        }
        this.loadingNum++;
      }, time);
    },
    isOnLine() {
      // navigator.onLine 获取设备是否可以上网、连接网络
      if (!navigator.onLine) {
        this.$message({
          type: "error",
          center: true,
          message: "网络断开连接",
        });
        return false;
      }
      return true;
    },
    // 点击确定使用这n张图片投放广告
    compositePicture() {
      console.log(`图片总大小：${this.uploadImgAllSize}MB(四舍五入)`);
      bus.$emit("openLoadingDialog", {
        num: this.uploadImgAllSize, // 0表示进度条2秒执行到100%
        type: "img",
      });
      const pictureUrlList = [...this.previewSrcList];
      // console.log(pictureUrlList);
      // 图片合成视频
      mergeVideo({
        picture_url_list: pictureUrlList,
        duration: this.videoDuration,
      })
        .then((res) => {
          // console.log(res);
          this.checkVideo(res.newVideoUrl);
        })
        .catch(() => {
          bus.$emit("closeLoadingDialog");
        });
    },
    // 预检 mp4
    checkVideo(newVideoUrl) {
      checkVideoUrl({
        video_url: newVideoUrl,
      })
        .then((res) => {
          // console.log(res);
          this.newVideoUrl = res.newVideoUrl;
          this.newVideoDuration = res.videoDuration;
          bus.$emit("closeLoadingDialog"); // 关闭加载中弹窗
          setTimeout(() => {
            this.uploadImgDialogSync = false; // 关闭上传图片弹窗
            this.$emit("closeDialogStatus"); // 关闭选择广告投放方式弹窗
            this.determineDialogSync = true; // 打开广告视频已生成，请确认的弹窗
          }, 200);
        })
        .catch(() => {
          bus.$emit("closeLoadingDialog");
        });
    },
    // 确定使用生成的图片视频投放广告
    onOkReLaunch() {
      this.determineDialogSync = false;
      this.$router.push({
        name: "AddLaunchInfo",
        query: {
          videoUrl: this.newVideoUrl,
          realVideoDuration: this.newVideoDuration,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .uploadCustom {
  .el-dialog__body {
    padding: 60rem 60rem 56rem;
    background: #f5f5f5;
    border-radius: 12rem;
  }
}
::v-deep .determineCustom {
  .el-dialog__body {
    padding: 60rem 60rem 48rem;
  }
}
::v-deep .el-input__inner {
  border-radius: 12rem;
  font-size: 16rem;
  font-weight: 400;
  color: #252927;
}
::v-deep .el-input-number {
  width: 192rem;
  height: 44rem;
  line-height: 44rem;
}
::v-deep .el-input-number__decrease,
::v-deep .el-input-number__increase {
  border: none;
  width: 32rem;
  height: 32rem;
  line-height: 32rem;
  border-radius: 6rem;
  background: #fff;
  color: #333;
  font-size: 16rem;
  top: 6rem;
}
::v-deep .el-input-number__decrease {
  left: 10rem;
  &:hover {
    background-color: #e7e7e7;
  }
}
::v-deep .el-input-number__increase {
  right: 10rem;
  &:hover {
    background-color: #e7e7e7;
  }
}
.reLaunchImgDialog {
  .dialog-title {
    font-size: 18rem;
    color: #252927;
    line-height: 25rem;
  }
  .uploadImg-view {
    width: 578rem;
    height: 217rem;
    background: #ffffff;
    border-radius: 6rem;
    margin-top: 32rem;
    padding: 0 32rem;
    .ossImgList {
      margin: 36rem 0 14rem;
      .ossImgItem {
        width: 154rem;
        height: 87rem;
        background: #f6f6f6;
        border: 1px solid rgba(0,0,0,0.05);
        border-radius: 5rem;
        position: relative;
        cursor: pointer;
        .icon-close {
          position: absolute;
          right: -11rem;
          top: -11rem;
          z-index: 10000;
          width: 22rem;
        }
        .image {
          width: 100%;
          height: 100%;
          border-radius: 5rem;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 9999;
        }
        .upload-view {
          position: absolute;
          right: 0;
          top: 0;
          border-radius: 5rem;
          z-index: 9998;
          width: 100%;
          height: 100%;
          img {
            width: 22rem;
          }
          span {
            font-size: 12rem;
            color: #252927;
            line-height: 17rem;
            margin-top: 12rem;
          }
        }
        .upload-view-loading {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          z-index: 9999;
          border-radius: 5rem;
          position: absolute;
          top: 0;
          left: 0;
          span {
            font-size: 16rem;
            color: #ffffff;
            line-height: 22rem;
          }
          img {
            width: 100rem;
            height: 100rem;
          }
          .loading-txt {
            width: 100%;
            text-align: center;
            position: absolute;
            left: 0;
            font-size: 14rem;
          }
        }
      }
    }
    .desc1,
    .desc2,
    .desc3 {
      font-size: 13rem;
      color: #a4a6a5;
      line-height: 20rem;
    }
  }
  .videoDuration-view {
    width: 578rem;
    height: 113rem;
    background: #ffffff;
    border-radius: 6rem;
    margin-top: 20rem;
    padding: 20rem 32rem;
    .border-left {
      width: 3rem;
      height: 18rem;
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
    }
    .label,
    .unit {
      font-size: 16rem;
      color: #252927;
      line-height: 22rem;
    }
    .label {
      margin: 0 8rem 0 14rem;
    }
    .unit {
      margin-left: 8rem;
    }
    .desc {
      font-size: 13rem;
      color: #a4a6a5;
      line-height: 20rem;
      margin: 9rem 0 0 17rem;
    }
  }
  .allVideoDuration-view {
    width: 578rem;
    height: 70rem;
    background: #ffffff;
    border-radius: 6rem;
    margin-top: 20rem;
    padding: 24rem 32rem;
    .border-left {
      width: 3rem;
      height: 18rem;
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
    }
    .txt1 {
      font-size: 16rem;
      color: #252927;
      line-height: 22rem;
      margin: 0 8rem 0 14rem;
    }
    .txt2 {
      font-size: 16rem;
      color: #252927;
      line-height: 22rem;
    }
  }
  .dialog-footer {
    margin-top: 32rem;
    div:nth-child(1) {
      width: 152rem;
      height: 46rem;
      border-radius: 25rem;
      border: 1rem solid #32c3f6;
      text-align: center;
      line-height: 46rem;
      color: #2bb3e3;
      font-weight: 500 !important;
      font-size: 18rem !important;
    }
    div:nth-child(2) {
      width: 152rem;
      height: 46rem;
      border-radius: 25rem;
      text-align: center;
      line-height: 46rem;
      color: #fff;
      margin-left: 44rem;
      font-weight: 500 !important;
      font-size: 18rem !important;
    }
    .disabled-btn {
      width: 152rem;
      height: 46rem;
      border-radius: 25rem;
      text-align: center;
      line-height: 46rem;
      margin-left: 44rem;
      font-weight: 500 !important;
      background: #ececec !important;
      color: #9c9d9d !important;
      cursor: not-allowed !important;
    }
  }
}
.determineDialog {
  .video {
    width: 578rem;
    max-height: 600rem;
  }
  .dialog-title {
    font-size: 18rem;
    color: #252927;
    line-height: 25rem;
    margin-top: 45rem;
  }
  .newVideoDuration {
    font-size: 18rem;
    color: #252927;
    line-height: 25rem;
    margin-top: 20rem;
  }
  .dialog-footer {
    margin-top: 44rem;
    div:nth-child(1) {
      width: 152rem;
      height: 46rem;
      border-radius: 25rem;
      border: 1rem solid #32c3f6;
      text-align: center;
      line-height: 46rem;
      color: #2bb3e3;
      font-weight: 500 !important;
      font-size: 18rem !important;
    }
    div:nth-child(2) {
      width: 152rem;
      height: 46rem;
      border-radius: 25rem;
      text-align: center;
      line-height: 46rem;
      color: #fff;
      margin-left: 44rem;
      font-weight: 500 !important;
      font-size: 18rem !important;
    }
  }
}
</style>
