/* eslint-disable no-unused-vars */
import { checkVideoUrl } from "@/api/request";
import router from '../router';
import store from '../store';
import bus from "./bus";
import canUseCanva from "./canUseCanva";
import initCanva from "./initCanva";

function createNewDesign(canvaApi) {
  canvaApi.createDesign({
    design: {
      type: 'VideoCover',
      // template: isSquare ? 'EAETGsFTIt4' : 'EAE5_eE1Ix4', // 默认模板ID（包含正方形）
      template: 'EAE5_eE1Ix4', // 默认模板ID
      title: '【当前正在编辑广告】完成编辑后，请点击“下一步 ”...', // 默认模板标题
      // dimensions: isSquare ? {（正方形）
      //   width: 1080,
      //   height: 1080,
      // } : '',
    },
    editor: {
      fileType: "mp4",
      publishLabel: "下一步",
    },
    onDesignOpen: (opts) => {
      // console.log("opts.designId", opts.designId);
    },
    onDesignClose: () => {
      // code goes here
    },
    onDesignPublish: (opts) => {
      console.log(opts);
      const audio = new Audio(opts.exportUrl);
      // 音频测视频时长
      audio.addEventListener("loadedmetadata", (e) => {
        const duration = Math.round(audio.duration);
        console.log(duration);
        bus.$emit("openLoadingDialog", {
          num: duration,
          type: 'canva',
        });
      });
      checkVideoUrl({
        video_url: opts.exportUrl,
        design_id: opts.designId,
        design_title: opts.designTitle == '【当前正在编辑广告】完成编辑后，请点击“下一步 ”...' ? '' : opts.designTitle,
      })
        .then((res) => {
          // console.log(res);
          bus.$emit("closeLoadingDialog");
          router.push({
            name: 'AddLaunchInfo',
            query: {
              videoUrl: res.newVideoUrl,
              realVideoDuration: res.videoDuration,
            },
          });
        })
        .catch(() => {
          bus.$emit("closeLoadingDialog");
        });
    },
  });
}

function createOldDesign(canvaApi, desginId) {
  canvaApi.createDesign({
    design: {
      type: "VideoCover",
      template: desginId,
      title: '【当前正在编辑广告】完成编辑后，请点击“下一步 ”...',
    },
    editor: {
      fileType: "mp4",
      publishLabel: "下一步",
    },
    onDesignOpen: (opts) => {
      // console.log("opts.designId", opts.designId);
    },
    onDesignClose: () => {
      // code goes here
    },
    onDesignPublish: (opts) => {
      const audio = new Audio(opts.exportUrl);
      // 音频测视频时长
      audio.addEventListener("loadedmetadata", (e) => {
        const duration = Math.round(audio.duration);
        console.log(duration);
        bus.$emit("openLoadingDialog", {
          num: duration,
          type: 'canva',
        });
      });
      checkVideoUrl({
        video_url: opts.exportUrl,
        design_id: opts.designId,
        design_title: opts.designTitle == '【当前正在编辑广告】完成编辑后，请点击“下一步 ”...' ? '' : opts.designTitle,
      })
        .then((res) => {
          // console.log(res);
          bus.$emit("closeLoadingDialog");
          router.push({
            name: 'AddLaunchInfo',
            query: {
              videoUrl: res.newVideoUrl,
              realVideoDuration: res.videoDuration,
            },
          });
        })
        .catch(() => {
          bus.$emit("closeLoadingDialog");
        });
    },
  });
}

function editCanvaDesign(canvaApi, desginId) {
  canvaApi.editDesign({
    design: {
      type: "VideoCover",
      id: desginId,
      title: '【当前正在编辑广告】完成编辑后，请点击“下一步 ”...',
    },
    editor: {
      fileType: "mp4",
      publishLabel: "下一步",
    },
    onDesignOpen: (opts) => {
      // console.log("opts.designId", opts.designId);
    },
    onDesignClose: () => {
      // code goes here
    },
    onDesignPublish: (opts) => {
      const audio = new Audio(opts.exportUrl);
      // 音频测视频时长
      audio.addEventListener("loadedmetadata", (e) => {
        const duration = Math.round(audio.duration);
        console.log(duration);
        bus.$emit("openLoadingDialog", {
          num: duration,
          type: 'canva',
        });
      });
      checkVideoUrl({
        video_url: opts.exportUrl,
        design_id: opts.designId,
        design_title: opts.designTitle == '【当前正在编辑广告】完成编辑后，请点击“下一步 ”...' ? '' : opts.designTitle,
      })
        .then((res) => {
          // console.log(res);
          bus.$emit("closeLoadingDialog");
          router.push({
            name: 'AddLaunchInfo',
            query: {
              videoUrl: res.newVideoUrl,
              realVideoDuration: res.videoDuration,
            },
          });
        })
        .catch(() => {
          bus.$emit("closeLoadingDialog");
        });
    },
  });
}

// 新建canva模板
// function onClickCreate(isNoticePage = false, isCanvaTypePage = false, isSquare = false) { （正方形）
function onClickCreate(isNoticePage = false) {
  if (!localStorage.getItem("token")) {
    router.push("/login").catch(() => {});
    return false;
  }
  if (!isNoticePage) {
    bus.$emit("openLaunchNoticeDialog");
    return false;
  }
  // 模板比例类型弹窗
  // if (!isCanvaTypePage) {
  //   bus.$emit("openCanvaTypeDialog");
  //   return false;
  // }
  // 判断能否使用Canva
  canUseCanva().then(() => {
    const { canvaApi } = store.state;
    if (!canvaApi) {
      // 如果能使用，首次需要初始化Canva
      initCanva().then((canvaApiRes) => {
        createNewDesign(canvaApiRes);
      });
    } else {
      createNewDesign(canvaApi);
    }
  }).catch(() => {});
}

// 编辑canva模板（模板中心的模板）
function onClickEdit(desginId, isNoticePage = false) {
  if (!localStorage.getItem("token")) {
    router.push("/login").catch(() => {});
    return false;
  }
  if (!isNoticePage) {
    bus.$emit("openTenCenterLaunchDialog", desginId);
    return false;
  }
  // 判断能否使用Canva
  canUseCanva().then(() => {
    const { canvaApi } = store.state;
    if (!canvaApi) {
      // 如果能使用，首次需要初始化Canva
      initCanva().then((canvaApiRes) => {
        createOldDesign(canvaApiRes, desginId);
      });
    } else {
      createOldDesign(canvaApi, desginId);
    }
  }).catch(() => {});
}

// 二次编辑canva模板（再次投放的模板）
function onClickAgainEdit(desginId, isNoticePage = false) {
  if (!localStorage.getItem("token")) {
    router.push("/login").catch(() => {});
    return false;
  }
  if (!isNoticePage) {
    bus.$emit("openTenCenterLaunchDialog", desginId);
    return false;
  }
  // 判断能否使用Canva
  canUseCanva().then(() => {
    const { canvaApi } = store.state;
    if (!canvaApi) {
      // 如果能使用，首次需要初始化Canva
      initCanva().then((canvaApiRes) => {
        editCanvaDesign(canvaApiRes, desginId);
      });
    } else {
      editCanvaDesign(canvaApi, desginId);
    }
  }).catch(() => {});
}

export default {
  onClickCreate,
  onClickEdit,
  onClickAgainEdit,
};
