<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <launchNotice />
    <!-- <canvaType /> -->
    <servicePopover />
    <miniProgram />
    <backtop />
    <loadingMessageBox />
    <cannotUseCanvaDialog />
  </div>
</template>
<script>
import { getListDaping } from "@/api/request";
import moment from "moment";
import bus from "./utils/bus";
import launchNotice from "./components/launchNotice.vue"; // 投放广告弹窗
// import canvaType from "./components/canvaType.vue";
import servicePopover from "./components/servicePopover.vue"; // 联系客服
import backtop from "./components/backtop.vue"; // 返回顶部
import loadingMessageBox from "./components/loadingMessageBox.vue"; // 图片合成为视频弹窗
import cannotUseCanvaDialog from "./components/cannotUseCanvaDialog.vue"; // 无权限使用canva弹窗
import miniProgram from "./components/miniProgram.vue"; // 小程序

const packageInfo = require("../package.json");

export default {
  components: {
    launchNotice,
    // canvaType,
    servicePopover,
    backtop,
    loadingMessageBox,
    cannotUseCanvaDialog,
    miniProgram,
  },
  data() {
    return {
      token: localStorage.getItem("token") || "",
      canvaTimer: null, // canva定时器
    };
  },
  created() {
    this.getListDaping();
    this.copyRightConsole(packageInfo);
    // 监听网络
    // 网络由异常到正常时触发
    window.addEventListener("online", this.updateOnlineStatus);
    // 网络由正常到异常触发
    window.addEventListener("offline", this.updateOfflineStatus);
  },
  mounted() {
    // if (navigator.userAgent.indexOf("Mac OS X") !== -1) {
    //   document.getElementsByTagName('body')[0].classList.add("daping_mac");
    // } else {
    //   document.getElementsByTagName('body')[0].classList.add("daping_pc");
    // }
    if (this.isMobile()) {
      // console.log("手机端");
      const url = window.location.search;
      if (url.indexOf("?") != -1) { // 是否是分享页
        // 是否有参数
        const strs = url.substr(1);
        const paramName = strs.split("=")[0];
        const paramValue = strs.split("=")[1];
        if (paramName == 'code') {
          // const BASE_URL = 'http://10.0.2.147:8081';
          const BASE_URL = process.env.VUE_APP_BASE_URL;
          window.location.replace(`${BASE_URL}/h5/#/pages/share/index?code=${paramValue}`);
        }
      } else {
        const BASE_URL = process.env.VUE_APP_BASE_URL;
        if (window.location.pathname == "/others/launch") {
          window.location.replace(`${BASE_URL}/h5/#/pages/launch/index`);
        } else if (window.location.pathname == "/memberCenter/productPrice") {
          window.location.replace(`${BASE_URL}/h5/#/pages/productPrice/index`);
        } else if (window.location.pathname == "/others/screenCooperate") {
          window.location.replace(`${BASE_URL}/h5/#/pages/screenCooperate/index`);
        } else {
          window.location.replace(`${BASE_URL}/h5/index.html#/`);
        }
      }
    }
  },
  methods: {
    updateOnlineStatus() {
      // this.$message({
      //   type: "success",
      //   center: true,
      //   message: "网络连接成功",
      // });
      window.location.reload();
    },
    updateOfflineStatus() {
      this.$message({
        type: "error",
        center: true,
        message: "网络断开连接",
      });
      // 发送断网状态给其他组件
      bus.$emit("OfflineStatus");
    },
    isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    clearCanvaTimer() {
      if (this.canvaTimer) {
        clearInterval(this.canvaTimer);
        this.canvaTimer = null;
      }
    },
    // 获取屏幕信息
    getListDaping() {
      getListDaping({}).then((res) => {
        this.$store.dispatch("screenListActions", res.list);
      });
    },
    // 在console中显示版本等信息
    copyRightConsole(packageData) {
      /* 样式代码 */
      const projectNameStyle =
        "font-size: 20px;font-weight: 600;color: rgb(244,167,89);";
      const descriptionStyle =
        "font-style: oblique;font-size:14px;color: rgb(244,167,89);font-weight: 400;";
      const versionStyle = "color: rgb(30,152,255);padding:8px 0 2px;";
      const dateTimeStyle = "color: rgb(30,152,255);padding:0 0 5px;";

      /* 内容代码 */
      const projectName = packageData.name || "";
      const description = packageData.description || "";
      const version = `版 本 号：${packageData.version}`;
      const packTime = `打包日期：${moment(new Date(window.versionTime)).format(
        "YYYY-MM-DD HH:mm:ss"
      )}`;
      const dateTime = `编译日期：${moment().format("YYYY-MM-DD HH:mm:ss")}`;

      // 空格有意义，不要格式化
      console.log(
        `%c${description} %c${projectName}
%c${version}
%c${packTime}
${dateTime}`,
        projectNameStyle,
        descriptionStyle,
        versionStyle,
        dateTimeStyle
      );
    },
  },
  beforeDestroy() {
    this.clearCanvaTimer();
  },
};
</script>

<style lang="scss">
@import "./styles/common.css";
@import "./styles/element.css";

#app {
  font-size: 16rem;
  font-family: "-apple-system-font", "SF Pro", "Helvetica Neue",
    "Microsoft YaHei", Arial, "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // overflow-y: scroll;
}
</style>
