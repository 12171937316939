<template>
  <div class="main-header justify-between">
    <div class="flex-row flex-left align-center">
      <div class="pic1 flex-col" @click="goTop">
        <img
          class="pic3"
          referrerpolicy="no-referrer"
          src="@/assets/img/logo.png"
        />
      </div>
      <span class="word2">收银台</span>
    </div>
    <div class="flex-row flex-right justify-end align-center">
      <div
        class="block3 flex-col"
        id="default-btn"
        @click="onClick_login"
        v-if="!token"
      >
        <span class="txt1">注册/登录</span>
      </div>
      <el-dropdown
        v-else
        class="user-dropdown flex-row justify-center align-center"
        id="default-btn"
        @command="handleCommand"
        :show-timeout="0"
      >
        <div class="icon-user flex-row justify-center align-center">
          <img
            class="icon"
            referrerpolicy="no-referrer"
            src="@/assets/icon/user.png"
          />
          <span class="txt">我的账户</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="memberCenter">会员中心</el-dropdown-item>
          <el-dropdown-item command="myOrder">我的订单</el-dropdown-item>
          <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: localStorage.getItem("token") || "",
      currentPath: this.$route.path,
    };
  },
  mounted() {
    // console.log("this.token", this.token);
  },
  computed: {
    newDiscountStatus() {
      return this.$store.state.newDiscountStatus;
    },
  },
  methods: {
    goTop() {
      this.$router.push("/");
    },
    onClick_login() {
      this.$router.push("/login").catch(() => {});
    },
    handleCommand(command) {
      if (command === "logOut") {
        this.logOut();
      }
      if (command === "memberCenter") {
        this.$router.push({ name: "Gold" });
      }
      if (command === "myOrder") {
        this.$router.push("/myOrder");
      }
    },
    logOut() {
      // localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("timestamp");
      this.$router.push("/login").catch(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
.main-header {
  // width: 100%;
  height: 70rem;
  background-color: rgba(255, 255, 255, 1);
  position: sticky;
  top: 0;
  z-index: 999;
  overflow-x: auto;
  margin: 0 auto;
  border-bottom: 1rem solid #ebebeb;
  .flex-left {
    width: 40%;
    padding-left: 10%;
    .pic1 {
      height: 45rem;
      width: 182rem;
      cursor: pointer;
      .pic3 {
        width: 100%;
      }
    }
    .word1 {
      font-size: 17rem;
      font-weight: 400;
      color: #252927;
      margin-left: 15%;
      cursor: pointer;
    }
    .word2 {
      width: 72rem;
      height: 25rem;
      display: block;
      overflow-wrap: break-word;
      color: #252927;
      font-size: 17rem;
      white-space: nowrap;
      line-height: 25rem;
      text-align: left;
      margin-left: 15%;
      cursor: pointer;
    }
  }
  .flex-right {
    width: 60%;
    height: 100%;
    padding-right: 10%;
    .user-dropdown {
      width: 141rem;
      height: 44rem;
      border-radius: 22rem;
      border: 1rem solid #dedede;
      margin-left: 36rem;
      .icon-user {
        height: 100%;
        .icon {
          width: 22rem;
          color: #252927;
        }
        span {
          font-size: 17rem;
          color: #252927;
          margin-left: 5rem;
        }
      }
      .el-icon-user {
        cursor: pointer;
        color: #252927;
        font-size: 25rem;
        span {
          font-size: 17rem;
          color: #252927;
          margin-left: 5rem;
        }
      }
    }
    .block3 {
      width: 141rem;
      height: 48rem;
      border-radius: 24rem;
      border: 1rem solid #dedede;
      margin-left: 30rem;
      cursor: pointer;
      .txt1 {
        // width: 81rem;
        height: 25rem;
        display: block;
        overflow-wrap: break-word;
        color: rgba(37, 41, 39, 1);
        font-size: 17rem;
        white-space: nowrap;
        line-height: 25rem;
        text-align: center;
        margin: 12rem 0 0 30rem;
      }
    }
  }
}
</style>
