import { getCanvaJwt } from "@/api/request";
import { Message } from 'element-ui';
import store from '../store';

export default function initCanva() {
  return new Promise((resolve, reject) => {
    // 如果不支持canva
    if (!window.Canva || !window.Canva.DesignButton) {
      Message({
        message: '您的浏览器不支持Canca可画',
        type: 'warning',
        duration: 2500,
      });
      return;
    }
    getCanvaJwt({})
      .then(async (res) => {
        const CanvaApi = await window.Canva.DesignButton.initialize({
          apiKey: res.apiKey,
          autoAuthToken: res.jwtToken,
        });
        if (CanvaApi) {
          store.dispatch("canvaApiActions", CanvaApi);
          resolve(CanvaApi);
        } else {
          reject();
        }
      })
      .catch((errCode) => {
        console.log("错误信息", errCode);
        reject();
      });
  });
}
