<template>
  <div
    :class="{
      'main-header': true,
      'main-header-En': currentPathName == 'CooperationEn',
      scrollTopBg: !scrollTopType,
    }"
  >
    <div class="flex-row flex-left align-center">
      <div class="pic1 flex-col" @click="goTop">
        <img
          v-if="currentPathName == 'CooperationEn'"
          class="pic3"
          referrerpolicy="no-referrer"
          src="@/assets/img/cooperationEn_logo.png"
        />
        <img
          v-else
          class="pic3"
          referrerpolicy="no-referrer"
          src="@/assets/img/logo.png"
        />
      </div>
    </div>
    <div
      v-if="currentPathName == 'Cooperation'"
      class="flex-row flex-right justify-end align-center"
    >
      <el-dropdown @command="handleCommand" :show-timeout="0">
        <div class="language-btn flex-row justify-center align-center">
          <span class="txt">简体中文</span>
          <img
            class="icon"
            referrerpolicy="no-referrer"
            src="@/assets/icon/angle-down.png"
          />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="en">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else class="flex-row flex-right justify-end align-center">
      <el-dropdown @command="handleCommand" :show-timeout="0">
        <div class="language-btn-En flex-row justify-center align-center">
          <span class="txt">English</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="cn">简体中文</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTopType: false,
    };
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    }
  },
  mounted() {
    window.onscroll = () => {
      const t = document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTopType = t > 70;
    };
  },
  methods: {
    goTop() {
      this.goHome();
    },
    handleCommand(command) {
      if (command === "cn") {
        this.goHome();
      }
      if (command === "en") {
        this.$router.push({ name: "CooperationEn" });
      }
    },
    goHome() {
      let BASE_URL = "";
      if (process.env.NODE_ENV == "development") {
        BASE_URL = `${window.location.protocol}//${window.location.host}`; // 开发版本使用
      } else {
        BASE_URL = process.env.VUE_APP_BASE_URL;
      }
      window.open(BASE_URL);
    },
  },
};
</script>
<style scoped lang="scss">
.main-header {
  height: 70rem;
  background-color: rgba(255, 255, 255, 1);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 999;
  overflow-x: auto;
  margin: 0 auto;
  border-bottom: 1rem solid #ebebeb;
  display: flex;
  justify-content: space-between;

  .flex-left {
    width: 60%;
    padding-left: 210rem;
    .pic1 {
      height: 45rem;
      width: 182rem;
      cursor: pointer;
      .pic3 {
        width: 100%;
      }
    }
  }
  .flex-right {
    width: 40%;
    height: 100%;
    padding-right: 210rem;
    .language-btn {
      width: 136rem;
      height: 40rem;
      border-radius: 22rem;
      border: 1rem solid #dedede;
      cursor: pointer;
      .icon {
        width: 10rem;
        color: #252927;
        margin-left: 8rem;
      }
      span {
        font-size: 16rem;
        color: #252927;
        line-height: 22rem;
      }
    }
    .language-btn-En {
      width: 136rem;
      height: 40rem;
      border: 1px solid #32c3f6;
      border-radius: 22rem;
      cursor: pointer;
      span {
        font-size: 16rem;
        color: #ffffff;
        line-height: 22rem;
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.main-header-En {
  background-color: rgba(24, 24, 27, 1);
  border-bottom: 1rem solid #18181B;
}
.scrollTopBg {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: none;
}
.el-dropdown-menu {
  width: 136rem;
  height: 38rem;
  background: #ffffff;
  box-shadow: 0px 0px 6rem 0px rgba(157, 139, 139, 0.05);
  border-radius: 19rem;
  border: none;
  padding: 0;
  margin: 6rem 0 0;
}

.el-dropdown-menu__item {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 38rem;
  font-size: 16rem;
  color: #252927;
  transition: all 0.3s;
  border-radius: 19rem;
  padding: 0;
}
::v-deep .popper__arrow {
  display: none;
}
</style>
