<template>
  <div class="layout-page">
    <img
      v-if="currentPathName == 'Login'"
      src="@/assets/img/banner_rightBg.png"
      class="banner_rightBg"
      alt=""
    />
    <demoHeaderAd
      v-if="
        hostname == 'demo.daping.cn' &&
        currentPathName != 'Cooperation' &&
        currentPathName != 'CooperationEn'
      "
    >
    </demoHeaderAd>
    <PayHeader
      v-if="
        currentPathName == 'PayOrder' ||
        currentPathName == 'PayStatus' ||
        currentPathName == 'MemCenPayStatus' ||
        currentPathName == 'BuyVipPayStatus'
      "
    ></PayHeader>
    <CooperationHeader
      v-else-if="
        currentPathName == 'Cooperation' || currentPathName == 'CooperationEn'
      "
    ></CooperationHeader>
    <Header v-else></Header>
    <div class="container">
      <router-view />
    </div>
    <Footer
      v-if="
        currentPathName != 'AddLaunchInfo' &&
        currentPathName != 'ListAdHistory' &&
        currentPathName != 'MyCanva' &&
        currentPathName != 'MyVideo' &&
        currentPathName != 'Cooperation' &&
        currentPathName != 'CooperationEn'
      "
    ></Footer>
    <CooperationFooter
      v-if="currentPathName == 'Cooperation'"
    ></CooperationFooter>
    <MailtoMailDialog ref="mailtoMailRef"></MailtoMailDialog>
    <temCenterLaunchNotice />
  </div>
</template>

<script>
import MailtoMailDialog from "@/components/mailtoMailDialog.vue";
import temCenterLaunchNotice from "@/components/temCenterLaunchNotice.vue";
import Header from "./components/header.vue";
import PayHeader from "./components/headerPay.vue";
import DemoHeaderAd from "./components/demoHeaderAd.vue";
import CooperationHeader from "./components/headerCooperation.vue";
import Footer from "./components/footer.vue";
import CooperationFooter from "./components/footerCooperation.vue";

export default {
  components: {
    Header,
    PayHeader,
    DemoHeaderAd,
    CooperationHeader,
    Footer,
    CooperationFooter,
    MailtoMailDialog,
    temCenterLaunchNotice,
  },
  data() {
    return {};
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    },
    hostname() {
      return window.location.hostname;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.layout-page {
  margin: 0 auto;
  position: relative;

  .banner_rightBg {
    width: 383rem;
    position: absolute;
    right: 0;
    top: 4rem;
    z-index: 184;
  }
  .container {
    width: 100%;
    background-color: #f9f9f9;
  }
}
</style>
