<template>
  <div>
    <el-popover
      class="popover"
      :placement="placement"
      trigger="hover"
      :visible-arrow="false"
      popper-class="servicePopover"
    >
      <div class="popover-content flex-col justify-between align-center">
        <span style="font-size: 14rem;margin-top: 5rem">电话：400-998-9272</span>
        <span style="font-size: 14rem;margin-top: 5rem">周一至周六：9:00-20:00</span>
      </div>
      <div slot="reference" class="reference flex-row align-center">
        <svg-icon class="service-svg" iconClass="service"></svg-icon>
        <div class="service-txt">{{ serviceTxt }}</div>
      </div>
    </el-popover>
  </div>
</template>

<script>
/* eslint-disable vue/require-valid-default-prop */
export default {
  props: {
    placement: {
      type: String,
      default: "top",
    },
    serviceTxt: {
      type: String,
      default: "联系客服",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.popover {
  .reference {
    color: #42b6fb;
    cursor: pointer;
    .service-svg {
      color: #42b6fb;
      width: 34rem;
      height: 34rem;
    }
    .service-txt {
      position: relative;
      right: 4rem;
      text-decoration: underline;
      // border-bottom: 1px solid #42b6fb;
    }
  }
}
</style>
