var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-page"},[(_vm.currentPathName == 'Login')?_c('img',{staticClass:"banner_rightBg",attrs:{"src":require("@/assets/img/banner_rightBg.png"),"alt":""}}):_vm._e(),(
      _vm.hostname == 'demo.daping.cn' &&
      _vm.currentPathName != 'Cooperation' &&
      _vm.currentPathName != 'CooperationEn'
    )?_c('demoHeaderAd'):_vm._e(),(
      _vm.currentPathName == 'PayOrder' ||
      _vm.currentPathName == 'PayStatus' ||
      _vm.currentPathName == 'MemCenPayStatus' ||
      _vm.currentPathName == 'BuyVipPayStatus'
    )?_c('PayHeader'):(
      _vm.currentPathName == 'Cooperation' || _vm.currentPathName == 'CooperationEn'
    )?_c('CooperationHeader'):_c('Header'),_c('div',{staticClass:"container"},[_c('router-view')],1),(
      _vm.currentPathName != 'AddLaunchInfo' &&
      _vm.currentPathName != 'ListAdHistory' &&
      _vm.currentPathName != 'MyCanva' &&
      _vm.currentPathName != 'MyVideo' &&
      _vm.currentPathName != 'Cooperation' &&
      _vm.currentPathName != 'CooperationEn'
    )?_c('Footer'):_vm._e(),(_vm.currentPathName == 'Cooperation')?_c('CooperationFooter'):_vm._e(),_c('MailtoMailDialog',{ref:"mailtoMailRef"}),_c('temCenterLaunchNotice')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }