<template>
  <el-popover
    v-if="currentPathName != 'CooperationEn'"
    class="popover"
    placement="left-end"
    trigger="hover"
    :visible-arrow="false"
    :style="'bottom:' + bottom + 'rem'"
    style="margin-bottom: 60rpx"
    popper-class="miniProgramPopover"
  >
    <div class="popover-content flex-col justify-between align-center">
      <div class="popover-Box">
        <div class="text1">扫码打开小程序</div>
        <div class="text2">手机投放更方便</div>
        <div class="img-box">
          <img src="../assets/img/miniprogram-img.jpg" alt="" />
        </div>
      </div>
    </div>
    <div slot="reference" class="reference">
      <img
        class="service-svg"
        src="../assets/icon/miniprogram-img.png"
        alt=""
      />
    </div>
  </el-popover>
</template>

<script>
import bus from "@/utils/bus";

export default {
  data() {
    return {
      bottom: 60,
      normalStatus: true,
    };
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    }
  },
  mounted() {
    bus.$on("isAddLaunchInfo", (status, num) => {
      this.normalStatus = status;
      if (!status) {
        if (num) {
          this.bottom = num;
        } else {
          this.bottom = 120;
        }
      } else {
        this.bottom = 60;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.popover {
  position: fixed;
  bottom: 60rem;
  right: 40rem;
  z-index: 101;

  .reference {
    width: 50rem;
    height: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s;
    box-shadow: 0 0 10rem 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    i {
      font-size: 20rem;
    }

    .service-svg {
      width: 48rem;
      height: 48rem;
    }

    &:hover {
      // background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);

      i {
        color: #fff;
      }

      .service-svg {
        color: #fff;
      }
    }
  }
}

.popover-Box {
  line-height: 22rem;
  font-size: 16rem;
  font-family: PingFang-SC-Regular, PingFang-SC;
  color: #252927;
  text-align: center;

  .text1 {
    height: 22rem;
    font-size: 18rem;
    font-weight: 600;
    margin-bottom: 10rem;
  }

  .text2 {
    height: 22rem;
    font-weight: 400;
  }
}

.img-box {
  max-width: 200rem;
  max-height: 200rem;
  margin: 0 auto;
  margin-top: 20rem;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
