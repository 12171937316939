<template>
  <el-dialog
    :visible.sync="loadingDialogVisible"
    width="480rem"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="dialog"
  >
    <div class="MessageBox-loading">
      <img src="@/assets/icon/loa.gif" />
      <span class="number" v-if="type != 'loading'">{{ number }}%</span>
      <span v-if="loadingTxt" class="loadingTxt">{{ loadingTxt }}</span>
      <span v-else-if="type == 'img'"
        >正将图片生成广告视频中，请您耐心等待...
      </span>
      <span v-else>正在上传您的视频，请您耐心等待...</span>
    </div>
  </el-dialog>
</template>

<script>
import bus from "@/utils/bus";

export default {
  data() {
    return {
      loadingDialogVisible: false,
      number: 1,
      countDownTimer: null,
      type: null,
      loadingTxt: null,
    };
  },
  created() {},
  mounted() {
    bus.$on("openLoadingDialog", (data) => {
      // console.log(data);
      this.loadingTxt = null;
      this.type = data.type;
      this.loadingDialogVisible = true;
      if (data.type == "loading") {
        this.loadingTxt = data.loadingTxt;
        return;
      }
      this.number = 1;
      if (data.type == "canva") {
        let time = 1000; // 初始化定时器速度
        if (data.num < 10) {
          time = 50;
        } else if (data.num >= 10 && data.num < 20) {
          time = 100;
        } else if (data.num >= 20 && data.num < 30) {
          time = 200;
        } else if (data.num >= 30 && data.num < 50) {
          time = 400;
        } else if (data.num >= 50 && data.num < 100) {
          time = 500;
        } else {
          time = 1000;
        }
        this.countDownTimer = setInterval(() => {
          if (!this.isOnLine()) return;
          if (this.number == 98) {
            clearInterval(this.countDownTimer);
            this.countDownTimer = null;
            return;
          }
          this.number++;
        }, time);
      }
      if (data.type == "local") {
        let time = 1000; // 初始化定时器速度
        if (data.num < 1) { // 1MB以内视频，上传到98%大概需要1秒
          time = 10;
        } else if (data.num < 5) { // 1-5MB视频，上传到98%大概需要5秒
          time = 50;
        } else if (data.num < 10) { // 5-10MB视频，上传到98%大概需要10秒
          time = 100;
        } else if (data.num >= 10 && data.num < 20) { // 10-20MB视频，上传到98%大概需要20秒
          time = 200;
        } else if (data.num >= 20 && data.num < 30) { // 20-30MB视频，上传到98%大概需要30秒
          time = 300;
        } else if (data.num >= 30 && data.num < 50) { // 30-50MB视频，上传到98%大概需要40秒
          time = 400;
        } else if (data.num >= 50 && data.num < 100) { // 50-100MB视频，上传到98%大概需要1.6分钟
          time = 1000;
        } else if (data.num >= 100 && data.num < 150) { // 100-150MB视频，上传到98%大概需要4分钟
          time = 2500;
        } else if (data.num >= 150 && data.num < 200) { // 150-200MB视频，上传到98%大概需要6.5分钟
          time = 4000;
        } else if (data.num >= 200 && data.num < 300) { // 200-300MB视频，上传到98%大概需要10分钟
          time = 6000;
        } else if (data.num >= 300 && data.num < 500) { // 300-500MB视频，上传到98%大概需要14.7分钟
          time = 9000;
        } else if (data.num >= 500 && data.num < 1000) { // 500-1000MB视频，上传到98%大概需要19.6分钟
          time = 12000;
        } else if (data.num >= 1000 && data.num < 2048) { // 1000-2048MB视频，上传到98%大概需要24.5分钟
          time = 15000;
        }
        // console.log(time);
        this.countDownTimer = setInterval(() => {
          if (!this.isOnLine()) return;
          if (this.number == 98) {
            clearInterval(this.countDownTimer);
            this.countDownTimer = null;
            return;
          }
          this.number++;
        }, time);
      }
      if (data.type == "img") {
        let time = 1000; // 初始化定时器速度
        if (data.num == 0) {
          time = 30;
        } else if (data.num > 0 && data.num <= 1) {
          time = 60;
        } else if (data.num > 1 && data.num <= 2) {
          time = 90;
        } else if (data.num > 2 && data.num <= 3) {
          time = 120;
        } else if (data.num > 3 && data.num <= 4) {
          time = 150;
        } else if (data.num > 4 && data.num <= 6) {
          time = 200;
        } else if (data.num > 6 && data.num <= 8) {
          time = 250;
        } else if (data.num > 8 && data.num <= 10) {
          time = 300;
        } else if (data.num > 10 && data.num <= 15) {
          time = 350;
        } else if (data.num > 15 && data.num <= 20) {
          time = 400;
        } else if (data.num > 20 && data.num <= 25) {
          time = 450;
        } else if (data.num > 25 && data.num <= 30) {
          time = 500;
        }
        this.countDownTimer = setInterval(() => {
          if (!this.isOnLine()) return;
          if (this.number == 98) {
            clearInterval(this.countDownTimer);
            this.countDownTimer = null;
            return;
          }
          this.number++;
        }, time);
      }
    });
    bus.$on("closeLoadingDialog", this.closeLoadingDialog);
  },
  computed: {},
  methods: {
    // 关闭弹窗
    closeLoadingDialog() {
      if (this.type == "loading") {
        this.loadingDialogVisible = false;
      } else {
        this.number = 100;
        this.clearTimer();
        setTimeout(() => {
          this.loadingDialogVisible = false;
        }, 200);
      }
    },
    isOnLine() {
      // navigator.onLine 获取设备是否可以上网、连接网络
      if (!navigator.onLine) {
        this.$message({
          type: "error",
          center: true,
          message: "网络断开连接",
        });
        this.loadingDialogVisible = false;
        this.clearTimer();
        return false;
      }
      return true;
    },
    clearTimer() {
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer);
        this.countDownTimer = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .MessageBox-loading {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .number {
      position: relative;
      bottom: 45rem;
      font-size: 16rem;
      color: #2cb0e2;
      line-height: 22rem;
    }
    .loadingTxt {
      margin-top: 26rem;
    }
    img {
      width: 70rem;
      height: 70rem;
    }
    span {
      font-size: 18rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      color: #252927;
      line-height: 25rem;
      text-align: center;
    }
  }
}

::v-deep .el-dialog {
  background: rgba(255, 255, 255, 1);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 44rem 66rem 46rem;
}
</style>
