const baseUrl = process.env.VUE_APP_BASE_API;
if (process.env.NODE_ENV === 'development') {
  // console.log("开发版本");
} else if (process.env.NODE_ENV === 'production') {
  // console.log("线上版本");
}
/* *api接口的统一管理* */
export default {
  getCanvaJwt: `${baseUrl}/v1/get_canva_jwt`, // 获取canva初始化数据
  getVerifyCode: `${baseUrl}/v1/get_verify_code`, // 获取验证码
  login: `${baseUrl}/v1/login`, // 登录
  wxWebScan: `${baseUrl}/v1/wx_web_scan`, // 微信网站应用扫码登陆，不需要授权，传入code
  wxWebBindPhone: `${baseUrl}/v1/wx_web_bind_phone`, // 微信网站应用绑定手机号，不需要授权，但是依赖WxWebScan返回的参数
  wxWebUnBind: `${baseUrl}/v1/wx_web_unbind`, // 解绑微信，需要登陆状态
  wxWebBind: `${baseUrl}/v1/wx_web_bind`, // 绑定微信，需要登陆状态
  getUserInfo: `${baseUrl}/v1/get_user_info_new`, // 获取自己的信息-新版，管理员也可以调用。获取自己的权益等信息请调用这个接口
  getUploadKeys: `${baseUrl}/v1/file/get_upload_keys`, // 获取文件上传临时密钥
  mergeVideo: `${baseUrl}/v1/merge_mp4_video`, // 图片合成视频
  checkVideoUrl: `${baseUrl}/v1/pre_check_mp4_video_url`, // 预检 mp4 url
  createOrder: `${baseUrl}/v1/create_order`, // 创建订单
  payOrder: `${baseUrl}/v1/pay_order`, // 支付订单（只能支付自己的订单，管理员除外）
  cancelOrder: `${baseUrl}/v1/cancel_order`, // 取消订单（只能取消自己的订单，管理员除外）
  deleteOrder: `${baseUrl}/v1/delete_order`, // 删除订单（只能删除自己已取消、已结束、审核不通过的订单，管理员除外）
  getOrder: `${baseUrl}/v1/get_order`, // 获取订单详情（只能获取自己的订单，管理员除外）
  getOrderTimer: `${baseUrl}/v1/get_order?timer=1`, // 定时器获取订单详情
  changeOrderStatus: `${baseUrl}/v1/admin/change_order_status`, // 更改订单状态，管理员审核
  getListOrder: `${baseUrl}/v1/list_order`, // 列出自己的订单（只能获取自己的订单，管理员除外）
  getListAd: `${baseUrl}/v1/list_ad`, // 列出自己的投放广告（只能获取自己的广告，管理员除外）
  getListAdHistory: `${baseUrl}/v1/list_ad_history`, // 列出已投放广告历史，也就是已经播出的（公开全局接口，所有用户的）
  getLiveAddress: `${baseUrl}/v1/get_live_address_new`, // 获取直播地址，支持多屏幕
  getCanvaTemplateList: `${baseUrl}/v1/list_template`, // 列出模板
  getStsInfo: `${baseUrl}/v1/file/get_upload_keys`, // 获取阿里云OSS临时上传密钥
  getConfig: `${baseUrl}/v1/get_config_new`, // 获取大屏是否在营业时间段
  getBuyTimeInfo: `${baseUrl}/v1/get_buy_time_info`, // 获取黄金会员一折购买情况
  createBuyTime: `${baseUrl}/v1/create_buy_time`, // 购买一折时长
  getBuyTimeOrderInfo: `${baseUrl}/v1/get_buy_time_order_info`, // 获取一折时长临时订单情况
  getListBusinessHistory: `${baseUrl}/v1/list_business_history`, // 获取时长的收入和支出详情
  editUserInfo: `${baseUrl}/v1/edit_user_info`, // 编辑自己的信息，管理员也可以调用，目前编辑头像和昵称
  getListDaping: `${baseUrl}/v1/list_daping`, // 列出大屏，公开接口
  createCommonBuyTime: `${baseUrl}/v1/create_common_buy_time`, // 购买普通时长
  getCommonBuyTimeOrderInfo: `${baseUrl}/v1/get_common_buy_time_order_info`, // 获取普通时长订单情况
  checkPictureByAi: `${baseUrl}/v1/check_picture_by_ai`, // AI检测图片
  shareOrder: `${baseUrl}/v1/share_order`, // 分享订单，获取分享码，普通用户可以分享自己的订单，舞笛管理员可以分享所有订单
  getShareCodeInfo: `${baseUrl}/v1/get_share_code_info`, // // 获取分享码信息
  getOrderByShareCode: `${baseUrl}/v1/get_order_by_share_code`, // 获取订单详情，分享码模式
  listAdByShareCode: `${baseUrl}/v1/list_ad_by_share_code`, // 列出投放广告，分享码模式
  createPackage: `${baseUrl}/v1/create_package`, // 支付订单（表白套餐）
  getPackageOrderInfo: `${baseUrl}/v1/get_package_order_info`, // 查询订单（支付订单（表白套餐））
  buyVip: `${baseUrl}/v1/buy_vip`, // 购买黄金会员
  getBuyVipOrderInfo: `${baseUrl}/v1/get_buy_vip_order_info`, // 获取购买黄金会员订单情况
  listMyVideo: `${baseUrl}/v1/list_my_video`, // 列出我的视频，管理员可用
  deleteMyVideo: `${baseUrl}/v1/delete_my_video`, // 删除我的视频
  deleteMyDesignVideo: `${baseUrl}/v1/delete_my_design_video`, // 删除我的设计
  updateOrderCover: `${baseUrl}/v1/update_order_cover`, // 修改自己的视频封面
  getMp4VideoUrl: `${baseUrl}/v1/get_mp4_video_url`, // 视频是否能够免审核
  contactUs: `${baseUrl}/v1/contact_us`, // 联系我们
};
