/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { Message } from 'element-ui';
/* 路由数据 */
import routes from './routes';
import store from '../store';
import { getUserInfo } from "../api/request";
import versionTood from '../libs/versionUpdate';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  routes,
});
// 路由白名单
const whiteList = ['/home', '/login', '/demoLogin', '/others/noviceTutorial', '/others/screenCooperate', '/others/launch', '/others/templateCenter', '/others/publicWelfareCooperation', '/others/listAdHistory', '/share', '/memberCenter/productPrice', '/cooperation', '/cooperationEn'];
// 全局路由前置守卫
router.beforeEach((to, from, next) => {
  // console.log(to, from);
  // 百度统计手动埋点
  // if (to.path) {
  //   if (window._hmt) {
  //     window._hmt.push(['_trackPageview', `/#${to.fullPath}`]);
  //   }
  // }
  // 如果是演示环境跳转登录
  if (to.path == "/login" && window.location.hostname == 'demo.daping.cn') {
    next('/demoLogin');
    return;
  }
  // 过期时间7天
  const time = 1000 * 60 * 60 * 24 * 7;
  const { localStorage, sessionStorage } = window;
  const isLogin = localStorage.getItem('token');
  function routerValid() {
    // 如果用户勾选过7天内自动登录
    if (localStorage.getItem('timestamp')) {
      const oldTimestamp = Number(localStorage.getItem('timestamp'));// 以前存的时间戳
      const nowTimestamp = Number(new Date().getTime());// 当前时间戳
      const sumTimestamp = oldTimestamp + time;// 以前存的时间戳+过期时间
      // 如果当前时间戳> 前面存的时间戳+过期时间 表示已经过期
      if (nowTimestamp > sumTimestamp) {
        console.log('用户超过7天没登录了');
        // localStorage.clear();
        localStorage.removeItem("token");
        localStorage.removeItem("timestamp");
        if (whiteList.indexOf(to.path) !== -1) {
          next();
        } else {
          Message({
            message: '登录过期，请重新登录',
            type: 'warning',
            duration: 1500,
            center: true,
          });
          next('/login');
        }
      } else {
        next();
      }
    } else {
      // 如果用户没有勾选过“7天内自动登录”，并且登录进来（走了登录）
      if (sessionStorage.getItem('greenPath') == 0) {
        next();
        return false;
      }
      // 如果用户没有勾选过“7天内自动登录”，并且没有登录直接进来系统（比如没走登录直接进入首页），则需要重新登录
      // localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("timestamp");
      next();
    }
  }
  if (isLogin) {
    // 如果用户token存在则往下执行。
    // 查找是否拥有新人优惠
    const newPath = ['Home', 'AddLaunchInfo', 'MyOrderIndex', 'MyOrderDetail'];
    if (newPath.indexOf(to.name) !== -1) {
      getUserInfo({}).then((res) => {
        // console.log(res, 'getUserInfoNew');
        store.dispatch("newDiscountStatusActions", res.userInfo.businessInfo.newDiscountStatus);
        store.dispatch("setUserInfoActions", res.userInfo);
        // store.commit('setCurrentRouteName', to.name);
        routerValid();
      });
      return false;
    }
    // store.commit('setCurrentRouteName', to.name);
    routerValid();
  } else {
    // 如果用户token不存在
    if (to.path != '/login') { // 没有登录情况下，储存用户上一个页面路由，方便登录后直接进来
      store.dispatch("prevPathActions", to.fullPath);
    }
    // store.commit('setCurrentRouteName', to.name);
    if (whiteList.indexOf(to.path) !== -1) { // 不需要登录就能查看的页面
      next();
    } else {
      // console.log(to);
      next('/login');
    }
  }
});
// 全局路由后置守卫
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => { // 这里不接收next
  // console.log(to, from);
  versionTood.isNewVersion();
  window.scrollTo(0, 0);
});
export default router;
