<template>
  <el-backtop
    v-if="currentPathName != 'CooperationEn'"
    :style="'bottom:' + bottom + 'rem !important'"
    :visibility-height="350"
  >
    <div class="backtop flex-row justify-center align-center">
      <svg-icon class="gotop-svg" iconClass="gotop"></svg-icon>
    </div>
  </el-backtop>
</template>

<script>
import bus from "@/utils/bus";

export default {
  data() {
    return {
      bottom: 240,
      normalStatus: true,
    };
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    }
  },
  mounted() {
    bus.$on("isAddLaunchInfo", (status, num) => {
      if (!status) {
        if (num) {
          this.bottom = num + 180;
        } else {
          this.bottom = 300;
        }
      } else {
        this.bottom = 240;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.el-backtop {
  width: 50rem;
  height: 50rem;
  right: 40rem !important;
  //   bottom: 150rem !important;
  box-shadow: none;
  background: #fff;
  border: 1rem solid #dedede;
  z-index: 101;
  .el-icon-arrow-up {
    transition: all 0.3s;
    color: #42b6fb;
  }
  .gotop-svg {
    width: 48rem;
    height: 48rem;
    color: #42b6fb;
  }
  &:hover {
    background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
    border: none;
    .el-icon-arrow-up {
      color: #fff;
    }
    .gotop-svg {
      color: #fff;
    }
  }
}
</style>
