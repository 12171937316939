/* http请求响应状态码枚举 */
export default [
  {
    value: '10001',
    label: '输入无效'
  },
  {
    value: '10002',
    label: '记录不存在'
  },
  {
    value: '10003',
    label: '操作失败，请刷新页面'
  },
  {
    value: '20000',
    label: '用户不存在'
  },
  {
    value: '20001',
    label: '用户没有权限'
  },
  {
    value: '20002',
    label: '用户密码错误'
  },
  {
    value: '20003',
    label: '验证码获取速度太快，请稍后再试'
  },
  {
    value: '20004',
    label: '验证码错误/已失效'
  },
  {
    value: '20005',
    label: '验证码已使用，请重新获取'
  },
  {
    value: '20006',
    label: '视频格式有误'
  },
  {
    value: '20007',
    label: '操作频率过快，请稍后再试'
  },
  {
    value: '20008',
    label: '抱歉！所选时间段已无可投放时间，请调整开始投放日期后重新提交'
  },
  {
    value: '20009',
    label: '新人折扣无效，不能使用（它被使用了或者在使用中）'
  },
  {
    value: '20010',
    label: '不支持这种支付方式'
  },
  {
    value: '20011',
    label: '订单不是你的，无权操作'
  },
  {
    value: '20012',
    label: '订单已经取消了'
  },
  {
    value: '20013',
    label: '订单已经支付了'
  },
  {
    value: '20014',
    label: '调用第三方支付出现错误'
  },
  {
    value: '20015',
    label: '订单不能审核，因为它不是等待审核状态'
  },
  {
    value: '20016',
    label: '广告不能操作-Windows端要告知投放成功还是失败，因为它不是同意投放状态'
  },
  {
    value: '20017',
    label: '订单不存在'
  },
  {
    value: '20018',
    label: '广告不存在'
  },
  {
    value: '20019',
    label: '大屏资源锁定繁忙，有人正在操作大屏，请稍后再试'
  },
  {
    value: '20020',
    label: '订单锁定繁忙，有人正在操作该订单，请稍后再试'
  },
  {
    value: '20021',
    label: '广告锁定繁忙，有人正在操作该广告，请稍后再试'
  },
  {
    value: '20022',
    label: '退款锁定繁忙，有人正在操作该退款，请稍后再试'
  },
  {
    value: '20023',
    label: '广告不能操作-Windows端，因为订单已经结束了'
  },
  {
    value: '20024',
    label: '验证码已过期'
  },
  {
    value: '20025',
    label: '订单不能手动结束，因为它不是审核通过投放中'
  },
  {
    value: '20026',
    label: '订单不能手动结束，因为退款超过限制，请刷新'
  },
  {
    value: '20027',
    label: '退款不能操作，因为不是等待状态'
  },
  {
    value: '20028',
    label: '订单不能操作退款，因为它还没结束'
  },
  {
    value: '20029',
    label: '订单不能操作退款，因为退款超过限制'
  },
  {
    value: '20030',
    label: '退款不存在'
  },
  {
    value: '20031',
    label: '账户剩余广告时长不足'
  },
  {
    value: '20032',
    label: '订单不能操作退款，因为没有投放失败的'
  },
  {
    value: '20033',
    label: '订单不能操作退款，因为不退款了'
  },
  {
    value: '21001',
    label: '创建订单，广告投放次数超出限制'
  },
  {
    value: '21002',
    label: '视频时长应在5秒至5分钟之间'
  },
  {
    value: '21003',
    label: '创建订单，待支付订单超出限制'
  },
  {
    value: '21005',
    label: '订单已经被用户删除'
  },
  {
    value: '21006',
    label: '订单无法删除'
  },
  {
    value: '39000',
    label: '存在大屏'
  },
  {
    value: '39001',
    label: '不是主屏'
  },
  {
    value: '39002',
    label: '副屏开机时间必须在主屏范围内'
  },
  {
    value: '39003',
    label: '屏幕已下线，请刷新页面'
  },
  {
    value: '40001',
    label: '不存在该大屏'
  },
  {
    value: '40002',
    label: '购买锁定繁忙，有人正在操作，请稍后再试'
  },
  {
    value: '40003',
    label: '订单不存在'
  },
  {
    value: '40004',
    label: '用户没有权限'
  },
  {
    value: '40005',
    label: '您不是黄金会员，不具备每月优惠充值资格'
  },
  {
    value: '40006',
    label: '普通购买锁定繁忙，请稍后再试'
  },
  {
    value: '40007',
    label: '普通临时订单不存在'
  },
  {
    value: '40008',
    label: '普通临时订单不是你的'
  },
  {
    value: '40009',
    label: '不是合作方'
  },
  {
    value: '40010',
    label: '合作方停用'
  },
  {
    value: '40011',
    label: '用户已经是合作方了'
  },
  {
    value: '40012',
    label: '用户邮箱已被使用'
  },
  {
    value: '40013',
    label: '模板已被使用'
  },
  {
    value: '40014',
    label: '模板不存在'
  },
  {
    value: '40015',
    label: '模板已经被删除'
  },
  {
    value: '40016',
    label: '招商视频不存在'
  },
  {
    value: '40017',
    label: '招商视频已经被删除'
  },
  {
    value: '40018',
    label: '分享不存在'
  },
  {
    value: '40019',
    label: '今天修改昵称次数已到限制，请明天再来吧'
  },
  {
    value: '40020',
    label: '今天修改头像次数已到限制，请明天再来吧'
  },
  {
    value: '40021',
    label: '绑定失败，该微信已绑定了其他手机号'
  },
  {
    value: '40022',
    label: '绑定失败'
  },
  {
    value: '40023',
    label: '特权用户不能操作这个大屏'
  },
  {
    value: '40024',
    label: '绑定失败，该手机号已经被绑定了'
  },
  {
    value: '40025',
    label: '用户没有绑定微信，请刷新页面'
  },
  {
    value: '40026',
    label: '用户已绑定微信，请刷新页面'
  },
  {
    value: '40027',
    label: '绑定失败，该微信已绑定了其他手机号'
  },
  {
    value: '40028',
    label: '不能操作购买成为会员，因为已经是黄金会员了'
  },
  {
    value: '40029',
    label: '不能操作升级黄金会员，因为不是会员'
  },
  {
    value: '40030',
    label: '不能操作升级会员，因为会员套餐快过期了，请稍后再试'
  },
  {
    value: '40031',
    label: '不能操作会员，因为套餐约束或者限制只能往上升'
  },
  {
    value: '40032',
    label: '不能操作会员，因为数据发生了改变，请重新刷新'
  },
  {
    value: '40033',
    label: '购买黄金会员锁定繁忙，请稍后再试'
  },
  {
    value: '40034',
    label: '购买黄金会员临时订单不存在'
  },
  {
    value: '40035',
    label: '购买黄金会员临时订单不是你的'
  },
  {
    value: '40036',
    label: '因成本原因，仅企业套餐用户可使用在线模版功能'
  },
  {
    value: '40200',
    label: '线上套餐锁定繁忙，请稍后再试'
  },
  {
    value: '40201',
    label: '线上套餐临时订单不存在'
  },
  {
    value: '40202',
    label: '线上套餐临时订单不是你的'
  },
  {
    value: '55555',
    label: '你设置的昵称涉嫌违规。请换个昵称试试'
  },
  {
    value: '55556',
    label: '你上传的图片涉嫌违规。请换张图片试试'
  },
  {
    value: '66666',
    label: '图片格式有误'
  },
  {
    value: '88888',
    label: '合成视频人数过多，请稍后再试'
  },
  {
    value: '88889',
    label: 'API废弃，请联系管理员'
  },
  {
    value: '99999',
    label: '系统繁忙，请稍后再试或者联系平台'
  }
];
