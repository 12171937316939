import axios from 'axios';

const isNewVersion = () => {
  const url = `//${window.location.host}/version.json`;
  axios.get(url).then((res) => {
    if (res.status === 200) {
      const currentVersion = res.data.version; // 当前版本号
      const localStorageVersion = localStorage.getItem('daping_version'); // 本地缓存版本号
      // 如果本地没有存版本号，默认为第一次使用了该功能，并且升级了版本需要更新 || 本地缓存版本号和项目最新版本号不一样，认为升级了版本需要更新
      if (!localStorageVersion || localStorageVersion != currentVersion) {
        localStorage.setItem('daping_version', currentVersion);
        window.location.reload();
      }
    }
  });
};
export default {
  isNewVersion
};
