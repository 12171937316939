import layout from '../layout/index.vue';
import homeLayout from '../layout/home.vue';

/* 路由映射数据 */
import routerMap from './routerMap';

const routes = [
  {
    path: '/',
    component: homeLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: routerMap.index,
      },
    ],
  },
  {
    path: '/login',
    component: layout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: routerMap.login,
      },
      {
        path: '/demoLogin',
        name: 'DemoLogin',
        component: routerMap.demoLogin,
      },
    ],
  },
  // {
  //   path: '/noviceTutorial',
  //   component: layout,
  //   redirect: '/NoviceTutorial',
  //   children: [
  //     {
  //       path: '/NoviceTutorial',
  //       name: 'NoviceTutorial',
  //       component: routerMap.noviceTutorial,
  //     },
  //   ],
  // },
  {
    path: '/others',
    name: 'Others',
    component: layout,
    redirect: '/others/noviceTutorial',
    children: [
      {
        path: '/others/noviceTutorial',
        name: 'NoviceTutorial',
        component: routerMap.noviceTutorial,
      },
      {
        path: '/others/screenCooperate',
        name: 'ScreenCooperate',
        component: routerMap.screenCooperate,
      },
      {
        path: '/others/launch',
        name: 'Launch',
        component: routerMap.launch,
      },
      // {
      //   path: '/others/templateCenter',
      //   name: 'TemplateCenter',
      //   component: routerMap.templateCenter,
      // },
      {
        path: '/others/publicWelfareCooperation',
        name: 'PublicWelfareCooperation',
        component: routerMap.publicWelfareCooperation,
      },
      {
        path: '/others/listAdHistory',
        name: 'ListAdHistory',
        component: routerMap.listAdHistory,
      },
      {
        path: '/share',
        name: 'Share',
        component: routerMap.share,
      },
      // {
      //   path: '/others/serviceAgreement',
      //   name: 'ServiceAgreement',
      //   component: routerMap.serviceAgreement,
      // },
      {
        path: '/cooperation',
        name: 'Cooperation',
        component: routerMap.cooperation,
      },
      {
        path: '/cooperationEn',
        name: 'CooperationEn',
        component: routerMap.cooperationEn,
      },
      // {
      //   path: '/others/newPeopleDiscounts',
      //   name: 'NewPeopleDiscounts',
      //   component: routerMap.newPeopleDiscounts,
      // },
    ],
  },
  {
    path: '/launchAd',
    component: layout,
    children: [
      {
        path: '/launchAd/addLaunchInfo',
        name: 'AddLaunchInfo',
        component: routerMap.addLaunchInfo,
      },
    ],
  },
  {
    path: '/launchAd',
    component: layout,
    children: [
      {
        path: '/launchAd/payOrder',
        name: 'PayOrder',
        component: routerMap.payOrder,
      },
      {
        path: '/launchAd/payStatus',
        name: 'PayStatus',
        component: routerMap.payStatus,
      },
    ],
  },
  {
    path: '/myOrder',
    name: 'MyOrder',
    redirect: '/myOrder/index',
    component: layout,
    children: [
      {
        path: '/myOrder/index',
        name: 'MyOrderIndex',
        component: routerMap.myOrderIndex,
      },
      {
        path: '/myOrder/detail',
        name: 'MyOrderDetail',
        component: routerMap.myOrderDetail,
      },
      {
        path: '/myOrder/myVideo',
        name: 'MyVideo',
        component: routerMap.myVideo,
      },
      // {
      //   path: '/myOrder/myCanva',
      //   name: 'MyCanva',
      //   component: routerMap.myCanva,
      // },
    ],
  },
  {
    path: '/memberCenter',
    name: 'MemberCenter',
    component: layout,
    children: [
      {
        path: '/memberCenter/gold',
        name: 'Gold',
        component: routerMap.gold,
      },
      // {
      //   path: '/memberCenter/payDuration',
      //   name: 'PayDuration',
      //   component: routerMap.payDuration,
      // },
      {
        path: '/memberCenter/editInfo',
        name: 'EditInfo',
        component: routerMap.editInfo,
      },
      {
        path: '/memberCenter/durationTable',
        name: 'DurationTable',
        component: routerMap.durationTable,
      },
      {
        path: '/memberCenter/payStatus',
        name: 'MemCenPayStatus',
        component: routerMap.memCenPayStatus,
      },
      {
        path: '/memberCenter/commonPayStatus',
        name: 'MemCenBcPayStatus',
        component: routerMap.memCenBcPayStatus,
      },
      // {
      //   path: '/memberCenter/productPrice',
      //   name: 'ProductPrice',
      //   component: routerMap.productPrice,
      // },
      {
        path: '/memberCenter/buyPackagePayStatus',
        name: 'BuyPackagePayStatus',
        component: routerMap.buyPackagePayStatus,
      },
      {
        path: '/memberCenter/buyVipPayStatus',
        name: 'BuyVipPayStatus',
        component: routerMap.buyVipPayStatus,
      },
    ],
  },
  {
    path: '/error',
    component: layout,
    redirect: '/404',
    children: [
      {
        path: '/404',
        name: 'Error',
        component: routerMap.error,
      },
    ],
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/error', hidden: true },
];

export default [
  ...routes,
];
