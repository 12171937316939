var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'main-header': true,
    isLogin: _vm.currentPathName == 'Login' ? true : false,
    scrollTopBg:
      _vm.transparentTopList.includes(_vm.currentPathName) && !_vm.scrollTopType,
  }},[_c('div',{staticClass:"flex-row flex-left align-center"},[_c('div',{staticClass:"pic1 flex-col",on:{"click":_vm.goTop}},[_c('img',{staticClass:"pic3",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/img/logo.png")}})]),_c('span',{staticClass:"home-txt",attrs:{"id":"txt-btn"},on:{"click":_vm.goTop}},[_vm._v("首页")]),_c('span',{staticClass:"other-txt",attrs:{"id":"txt-btn"},on:{"click":_vm.goNews}},[_vm._v("投放指南")]),_c('span',{staticClass:"other-txt",attrs:{"id":"txt-btn"},on:{"click":_vm.goScreenCooperate}},[_vm._v("屏幕合作")])]),_c('div',{staticClass:"flex-row flex-right justify-end align-center",style:(_vm.currentPathName == 'MyCanva' || _vm.currentPathName == 'MyVideo'
        ? 'padding-right: 220rem'
        : '')},[(_vm.currentPathName !== 'AddLaunchInfo')?_c('div',{staticClass:"advertising justify-align-center",attrs:{"id":"primary-btn"},on:{"click":_vm.launch}},[_c('img',{staticClass:"icon",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/img/add_icon.png")}}),_c('span',{staticClass:"txt"},[_vm._v("投放广告")])]):_vm._e(),(!_vm.token)?_c('div',{staticClass:"login-btn flex-col",style:(_vm.transparentTopList.includes(_vm.currentPathName)
          ? 'background-color: rgba(255, 255, 255, 0);'
          : ''),attrs:{"id":"default-btn"},on:{"click":_vm.onClick_login}},[_c('span',{staticClass:"txt"},[_vm._v("注册/登录")])]):_c('el-dropdown',{staticClass:"user-dropdown",style:(_vm.transparentTopList.includes(_vm.currentPathName)
          ? 'background-color: rgba(255, 255, 255, 0);'
          : ''),attrs:{"id":"default-btn","show-timeout":0},on:{"command":_vm.handleCommand}},[_c('div',{staticClass:"icon-user flex-row justify-center align-center"},[_c('img',{staticClass:"icon",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/icon/user.png")}}),_c('span',{staticClass:"txt"},[_vm._v("我的账户")])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"memberCenter"}},[_vm._v("会员中心")]),_c('el-dropdown-item',{attrs:{"command":"myOrder"}},[_vm._v("我的订单")]),_c('el-dropdown-item',{attrs:{"command":"logOut"}},[_vm._v("退出登录")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }