<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogStatus"
      class="dialog_box"
      width="1280rem"
    >
      <div class="flex-row justify-between">
        <div class="launch-mode flex-col">
          <span class="launch-mode-title">请选择广告投放方式</span>
          <div class="flex-row justify-between">
            <el-upload
              ref="upload"
              :show-file-list="false"
              action="action"
              accept=".mp4"
              :auto-upload="false"
              :on-change="changeFile"
            >
              <div class="local-video flex-col align-center">
                <img
                  class="mode-logo"
                  src="@/assets/img/local_reLaunch.png"
                  alt=""
                />
                <div class="mode-desc flex-row align-center">
                  <span>本地视频文件</span>
                  <img src="@/assets/img/stroke.png" alt="" />
                </div>
              </div>
            </el-upload>
            <div
              class="local-img flex-col align-center"
              @click="$refs.refUploadLocalImg.openDialog()"
            >
              <img class="mode-logo" src="@/assets/img/local_img.png" alt="" />
              <div class="mode-desc flex-row align-center">
                <span>本地图片文件</span>
                <img src="@/assets/img/stroke.png" alt="" />
              </div>
            </div>
          </div>
          <div class="flex-row justify-between">
            <div
              class="history-orders flex-col align-center"
              @click="goOrderList"
            >
              <img
                class="mode-logo"
                src="@/assets/img/history_reLaunch.png"
                alt=""
              />
              <div class="mode-desc flex-row align-center">
                <span>使用历史订单再次投放</span>
                <img src="@/assets/img/stroke.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="launch-notice flex-col">
          <div class="dialog_title flex-row align-center">
            <div></div>
            <span>广告投放流程</span>
          </div>
          <div class="flex-col process-list">
            <div
              v-for="(process, index) in processList"
              :key="index"
              class="process flex-col"
            >
              <div class="process-title flex-row align-center">
                <div>
                  {{ index + 1 }}
                </div>
                <span>{{ process.title }}</span>
              </div>
              <span class="process-tip" v-if="process.tips">{{
                process.tips
              }}</span>
              <span class="process-tips" v-if="process.tips2">{{
                process.tips2
              }}</span>
              <span class="process-tips" v-if="process.tips3">{{
                process.tips3
              }}</span>
              <span class="process-payType" v-if="process.payType">{{
                process.payType
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <localUploadErr
      ref="refLocalUploadErr"
      @localReplace="$refs.upload.$children[0].handleClick()"
      @closeDialogStatus="dialogStatus = false"
    ></localUploadErr>
    <uploadLocalImgDialog
      ref="refUploadLocalImg"
      @closeDialogStatus="dialogStatus = false"
    ></uploadLocalImgDialog>
    <el-dialog
      :visible.sync="reLaunchStatus"
      width="698rem"
      :show-close="false"
      custom-class="newReLaunch"
    >
      <div id="reLaunchDialog" class="flex-col align-center">
        <p id="reLaunchP">确定是使用这个视频投放广告吗？</p>
        <div slot="footer" class="dialog-footer flex-row">
          <div id="default-btn" @click="reLaunchStatus = false">取消</div>
          <div id="primary-btn" @click="onOkReLaunch">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/utils/bus";
import BMF from "browser-md5-file";
import { getAliToken, ossUpload } from "../utils/oss";
import { checkVideoUrl } from "../api/request";
import localUploadErr from "./localUploadErr.vue";
import uploadLocalImgDialog from "./uploadLocalImgDialog.vue";

const bmf = new BMF();

export default {
  components: {
    localUploadErr,
    uploadLocalImgDialog,
  },
  props: {},
  data() {
    return {
      dialogStatus: false,
      reLaunchStatus: false,
      launchData: {}, // 投放数据
      processList: [
        {
          title: "选择广告投放方式",
          tips: "*如果您已备好视频广告，可通过“本地视频文件”上传视频、投放广告",
          tips2:
            "*如果您已备好图片广告，可通过“本地图片文件”上传图片、投放广告"
        },
        {
          title: "填写投放次数、投放时间等信息",
        },
        {
          title: "完成订单支付",
          tips: "*计费单价：每秒1元",
          payType: "*支付方式：微信、支付宝",
        },
        {
          title: "等待客服通过广告的审核",
          tips: "*工作人员会在次日大屏开机时间之前完成审核",
        },
        {
          title: "等待系统投放广告到大屏",
        },
      ],
      // 上传oss
      region: "oss-cn-beijing",
      dataObj: {},
      uploadOssAddress: "",
      imgOssAddress: "",
      url: "",
      upLoadFile: "",
      currentVideoSize: 0, // 上传的视频大小
    };
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    },
    isBuyVip() {
      return this.$store.state.userInfo.businessInfo.isBuyVip;
    }
  },
  created() {},
  mounted() {
    bus.$on("openLaunchNoticeDialog", () => {
      this.openDialog();
    });
    bus.$on("closeLaunchNoticeDialog", () => {
      this.dialogStatus = false;
    });
  },
  watch: {
    reLaunchStatus(val) {
      if (!val) {
        this.closeReLaunchStatus();
      }
    },
  },
  methods: {
    // 打开弹窗
    openDialog() {
      this.dialogStatus = true;
    },
    onClick_create() {
      this.dialogStatus = false;
      this.$canvaUtil.onClickCreate(true);
    },
    closeReLaunchStatus() {
      const reLaunchDialog = document.getElementById("reLaunchDialog");
      const ElementVideo = document.getElementById("reLaunchVideo");
      if (!ElementVideo.paused) {
        ElementVideo.pause();
      }
      reLaunchDialog.removeChild(ElementVideo);
      this.reLaunchStatus = false;
    },
    onOkReLaunch() {
      this.reLaunchStatus = false;
      bus.$emit("openLoadingDialog", {
        num: this.currentVideoSize,
        type: "local",
      });
      bmf.md5(
        this.upLoadFile,
        (err, md5) => {
          // console.log("err:", err);
          // console.log("md5 string:", md5);
          if (md5) {
            checkVideoUrl({
              real_hash: md5,
            })
              .then((res) => {
                // 视频上传过，无需再上传
                if (res.existHash) {
                  bus.$emit("closeLoadingDialog");
                  setTimeout(() => {
                    this.dialogStatus = false;
                    this.$router.push({
                      name: "AddLaunchInfo",
                      query: {
                        videoUrl: res.newVideoUrl,
                        realVideoDuration: res.videoDuration,
                      },
                    });
                  }, 300);
                } else {
                  this.beforeUpload(this.upLoadFile);
                }
              })
              .catch(() => {
                bus.$emit("closeLoadingDialog");
              });
          } else {
            this.beforeUpload(this.upLoadFile);
          }
        },
        // eslint-disable-next-line no-unused-vars
        (progress) => {
          // console.log("progress number:", progress);
        }
      );
    },
    //  文件状态改变时的回调函数，添加文件、上传成功和上传失败时都会被调用
    changeFile(file) {
      // console.log(file);
      this.upLoadFile = file.raw;
      const size = file.raw.size / 1024 / 1024; // 上传文件的大小  MB
      this.currentVideoSize = Math.round(size);
      if (parseFloat(size) > 2048) {
        this.$message({
          type: "error",
          center: true,
          message: "请上传小于2G的视频",
        });
        return;
      }
      if (["mp4"].indexOf(file.raw.name.split(".").pop().toLowerCase()) == -1) {
        this.$message({
          type: "error",
          center: true,
          message: "请上传mp4格式的视频",
        });
        return;
      }
      // 渲染本地视频dom
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file.raw);
      videoElement.style = "max-width: 100%;max-height: 600rem";
      videoElement.id = "reLaunchVideo";
      videoElement.controls = true;
      videoElement.autoplay = true;
      videoElement.onerror = () => {
        this.$message({
          type: "error",
          center: true,
          message: "视频解析错误",
        });
      };
      videoElement.addEventListener("loadedmetadata", () => {
        console.log(
          "视频时长:",
          videoElement.duration,
          "视频宽:",
          videoElement.videoWidth,
          "视频高:",
          videoElement.videoHeight
        );
        // 读取不到视频的尺寸
        if (videoElement.videoWidth == 0 || videoElement.videoHeight == 0) {
          this.$message({
            type: "error",
            center: true,
            message: "视频尺寸解析错误",
          });
          if (videoElement) {
            videoElement.pause();
          }
          return;
        }
        // 视频时长不在5s-5分钟之间（考虑投放视频时，时长四舍五入的情况）
        if (videoElement.duration < 5 || videoElement.duration >= 300.5) {
          if (videoElement) {
            videoElement.pause();
          }
          this.$refs.refLocalUploadErr.openLocalUpErr();
          return;
        }
        // 视频尺寸（宽高比）不在1.3-1.78之间
        if (
          videoElement.videoWidth / videoElement.videoHeight < 1.3 ||
          videoElement.videoWidth / videoElement.videoHeight > 1.78
        ) {
          if (videoElement) {
            videoElement.pause();
          }
          this.$refs.refLocalUploadErr.openLocalUpErr();
          return;
        }
        if (videoElement.duration) {
          // 把视频插入dom
          this.reLaunchStatus = true;
          this.$nextTick(() => {
            const reLaunchDialog = document.getElementById("reLaunchDialog");
            const ElementsByIDP = document.getElementById("reLaunchP");
            reLaunchDialog.insertBefore(videoElement, ElementsByIDP);
            // console.log(reLaunchDialog, videoElement);
          });
        }
      });
    },
    // 上传之前获取sts 虚拟授权
    beforeUpload(file) {
      // console.log(file);
      const that = this;
      return new Promise((resolve, reject) => {
        // 获取sts 的 token
        getAliToken(that, file.name)
          .then((response) => {
            // console.log(response);
            if (response) {
              resolve(response);
              this.handleHttpRequest(file);
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async handleHttpRequest(file) {
      // console.log("file :>> ", file);
      // 上传OSS
      try {
        // 上传视频    this.dataObj为new OSS实例化的参数，上传的oss路径拼接this.uploadOssAddress
        await ossUpload(file, this.dataObj, this.uploadOssAddress, (res) => {
          const {
            res: { statusCode, requestUrls },
          } = res; // 返回状态和 url
          if (statusCode == 200) {
            const url = requestUrls[0].split("?")[0];
            this.url = url;
            // console.log(this.url);
            this.checkVideoUrl();
          }
        });
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    checkVideoUrl() {
      checkVideoUrl({
        video_url: this.url,
      })
        .then((res) => {
          bus.$emit("closeLoadingDialog");
          setTimeout(() => {
            this.dialogStatus = false;
            // console.log(res);
            this.$router.push({
              name: "AddLaunchInfo",
              query: {
                videoUrl: res.newVideoUrl,
                realVideoDuration: res.videoDuration,
              },
            });
          }, 300);
        })
        .catch(() => {
          bus.$emit("closeLoadingDialog");
          // loading.close();
        });
    },
    goOrderList() {
      this.dialogStatus = false;
      if (this.currentPathName == "MyOrderIndex") return;
      this.$router.push({
        name: "MyOrderIndex",
      });
    },
    goProductPrice() {
      this.$router.push({
        name: "ProductPrice",
        query: {
          active: 2,
        },
      });
      this.dialogStatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__body {
  padding: 60rem 75rem;
}
::v-deep .newReLaunch {
  .el-dialog__body {
    padding: 60rem 60rem 48rem;
  }
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 24rem;
}
::v-deep .el-dialog__header {
  padding: 0;
}
#reLaunchDialog {
  // padding: 0 60rem;
  p {
    margin: 30rem 0;
    font-size: 16rem;
    color: #252927;
  }
  .dialog-footer {
    div:nth-child(1) {
      width: 152rem;
      height: 46rem;
      background: #ffffff;
      border-radius: 25rem;
      border: 1rem solid #32c3f6;
      text-align: center;
      line-height: 46rem;
      color: #2bb3e3;
      &:hover {
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        color: #fff;
      }
    }
    div:nth-child(2) {
      width: 152rem;
      height: 46rem;
      border-radius: 25rem;
      text-align: center;
      line-height: 46rem;
      color: #fff;
      margin-left: 44rem;
    }
  }
}
.launch-mode {
  .launch-mode-title {
    font-size: 22rem;
    color: #252927;
    line-height: 30rem;
  }
  .local-video,
  .local-img,
  .onLine-video,
  .history-orders {
    width: 325rem;
    height: 257rem;
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 6rem;
    margin-top: 28rem;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      border: 2px solid #2ed3e7;
    }
    .mode-logo {
      width: 96rem;
      margin-top: 56rem;
    }
    .mode-desc {
      margin-top: 24rem;
      span {
        font-size: 18rem;
        color: #2bb3e3;
        line-height: 25rem;
      }
      img {
        width: 5rem;
        height: 10rem;
        margin-left: 10rem;
      }
    }
    .mode-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform:translate(-50%,-50%);
      font-family: PingFangSC-Regular, PingFang SC;
      background: #FFFFFF;
      border-radius: 10px;
      width: 289rem;
      // height: 69rem;
      padding: 10rem 13rem;
      font-size: 16rem;
      text-align: center;
      cursor: pointer;
      .txt-btn {
        color: #2BB3E3;
        display: inline-block;
        margin-top:5rem;
      }
      .img_box{
        width: 7rem;
        height: 12rem;
      }
    }
  }
  .history-orders {
    width: 100%;
  }
  .onLine-video2 {
    position: relative;
    cursor: not-allowed;
    background: rgba(0,0,0,0.25);
    border: none;
    &:hover {
      border: none;
    }
  }
  .local-img,
  .onLine-video,
  .onLine-video2 {
    margin-left: 28rem;
  }
}
.launch-notice {
  width: 403rem;
  padding: 30rem 32rem 0;
  background-color: #fff;
  border-radius: 6rem;
  .dialog_title {
    width: 100%;
    margin-bottom: 26rem;
    div {
      width: 3rem;
      height: 20rem;
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
    }
    span {
      font-size: 20rem;
      color: #252927;
      line-height: 28rem;
      margin-left: 14rem;
    }
  }
  .process-list {
    position: relative;
    // right: 16%;
  }
  .process {
    margin-bottom: 23rem;
    .process-title {
      margin-bottom: 8rem;
      div {
        width: 23rem;
        height: 23rem;
        border-radius: 50%;
        background: #ececec;
        text-align: center;
        line-height: 23rem;
        font-size: 16rem;
        color: #252927;
      }
      span {
        font-size: 18rem;
        color: #252927;
        margin-left: 16rem;
        line-height: 25rem;
      }
    }
    .process-tip,
    .process-tips,
    .process-payType {
      font-size: 16rem;
      color: #9b9d9b;
      line-height: 24rem;
      margin-left: 39rem;
    }
    .process-tips {
      margin-top: 6rem;
    }
  }
  .el-checkbox {
    margin: 7% 0 5% 0;
  }
  .edit-ad,
  .local-ad {
    width: 250rem;
    height: 40rem;
    border-radius: 26rem;
    text-align: center;
    line-height: 40rem;
    font-size: 16rem;
    color: #fff;
    margin: 3% 0 5% 0;
  }
  .create-ad {
    width: 250rem;
    height: 40rem;
    border-radius: 26rem;
    text-align: center;
    line-height: 40rem;
    font-size: 16rem;
    color: #fff;
  }
}
</style>
