/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prefer-promise-reject-errors */
import { getStsInfo } from '@/api/request'; // 获取后台返回sts信息
import Client from "./client";

export const getAliToken = (that, name) =>
  // 获取Token
  new Promise((resolve, reject) => {
    // console.log(name);
    getStsInfo({
      file_name: name,
    }).then((res) => {
      // console.log("res :>> ", res);
      if (res.key) {
        const {
          accessKeyId,
          accessKeySecret,
          bucketName,
          region,
          securityToken,
          objectKey,
        } = res.key;
        // console.log(that);
        // 后台返回的存放路径
        that.uploadOssAddress = objectKey;
        that.dataObj = {
          region: region.replace(".aliyuncs.com", ""),
          bucket: bucketName,
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
        };
        resolve(res);
      } else {
        reject(false);
      }
    })
      .catch((err) => {
        console.log(err);
      });
  });

// 上传视频 oss分片上传
export const ossUpload = async (fileData, dataObj, route, callback) => {
  // 分片上传文件
  const client = Client(dataObj);
  client.options.endpoint.protocol = "https:";
  await client
    .multipartUpload(route, fileData, {})
    .then((res) => {
      callback(res);
    });
};
// 上传图片 oss直传
export const ossUploadImg = async (fileData, dataObj, route, callback) => {
  const client = Client(dataObj);
  client.options.endpoint.protocol = "https:";
  await client
    .put(route, fileData)
    .then((res) => {
      callback(res);
    });
};
