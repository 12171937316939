<template>
  <div class="head-ad justify-center align-center">
    <span class="title">演示环境，仅供体验</span>
    <span class="goHome" @click="goHome">回到主站</span>
    <img class="news_bg" src="@/assets/img/news_bg.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goHome() {
      window.open('https://www.daping.cn/home', "_self");
    },
  },
};
</script>
<style scoped lang="scss">
.head-ad {
  z-index: 185;
  height: 50rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  .title {
    z-index: 190;
    color: rgba(37, 41, 39, 1);
    font-size: 17rem;
  }
  .goHome {
    z-index: 190;
    color: #2BB3E3;
    font-size: 17rem;
    position: absolute;
    right: 100rem;
    text-decoration: underline;
    cursor: pointer;
  }
  .news_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
