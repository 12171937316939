import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss';
import Axios from "axios";
import qs from 'qs';
import _ from 'lodash';
import moment from "moment";
import VueCanvasPoster from 'vue-canvas-poster';
import App from './App.vue';
import router from './router';
import store from './store';
import { post, get, request } from './utils/http';
import canvaUtil from './utils/canva';
import "./utils/filters";
import "./utils/loadMoreTable";
import './assets/icons';
import debAndThr from './utils/debAndThr';

// 引入全局可用阿里图标样式文件
import './assets/iconfont/iconfont.css';
// 引入全局icon基础样式，一般用于规定基础的svg宽高和图标大小
import './styles/iconfont.css';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueCanvasPoster);
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$request = request;
Vue.prototype.$axios = Axios;
Vue.prototype.$qs = qs;
Vue.prototype._ = _;
Vue.prototype.$moment = moment;
Vue.prototype.$canvaUtil = canvaUtil;
Vue.use(debAndThr);

// rem计算
function setRemPc() {
  const whdef = 1 / 1920;// 表示1920的设计图,使用1PX的默认值
  const bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  const rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = `${rem}px`;
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    setRemPc();
    window.addEventListener('resize', setRemPc);// 浏览器窗口大小改变时调用rem换算方法
  },
}).$mount('#app');
