import { getUserInfo } from "@/api/request";
// import { Message } from 'element-ui';
import bus from "./bus";
import store from '../store';

// 根据个人信息接口返回数据，判断该用户能否使用Canva可画
export default function canUseCanva() {
  return new Promise((resolve, reject) => {
    getUserInfo({})
      .then((res) => {
        store.dispatch("setUserInfoActions", res.userInfo);
        // 仅企业套餐用户可使用在线模版功能（Canva可画）
        const { isBuyVip } = res.userInfo.businessInfo;
        if (isBuyVip) {
          resolve();
        } else {
          bus.$emit("openCannotUseCanvaDialog");
          // Message({
          //   message: '因成本原因，仅企业套餐用户可使用在线模版功能',
          //   type: 'warning',
          //   duration: 3500,
          // });
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
}
