import Vue from 'vue';

function debounce(func, delay) {
  let timer = null;
  return function (...args) {
    if (timer) { clearTimeout(timer); }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
Vue.directive('loadMoreTable', {
  inserted(el, binding) {
    // 获取element-ui定义好的scroll盒子

    const SELECTWRAP_DOM = el.querySelectorAll('.el-table__body-wrapper')[0];

    SELECTWRAP_DOM.addEventListener('scroll', debounce(function () {
      const CONDITION = parseInt(this.scrollHeight - this.scrollTop) <= this.clientHeight;
      if (CONDITION) {
        binding.value();
      }
    }, 100));
  },
});
