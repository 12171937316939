/* eslint-disable no-shadow */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const state = {
  isMobile: false, // 判断当前页面是在手机端还是PC端，isMobile：true表示手机端，isMobile：false表示PC端。
  newDiscountStatus: null,
  canvaApi: null,
  prevPath: null,
  userInfo: {
    businessInfo: {
      isBuyVip: false
    }
  },
  screenList: [], // 屏幕列表
  // currentRouteName: ''
};
// 获取属性的状态
const getters = {
  getIsMobileValue: (state) => state.isMobile,
  getCanvaApi: (state) => state.canvaApi,
};
const mutations = {
  setIsMobile: (state, data) => {
    state.isMobile = data;
  },
  setNewDiscountStatus: (state, data) => {
    state.newDiscountStatus = data;
  },
  setCanvaApi: (state, data) => {
    state.canvaApi = data;
  },
  setPrevPath: (state, data) => {
    state.prevPath = data;
  },
  setUserInfo: (state, data) => {
    state.userInfo = data;
  },
  setScreenList: (state, data) => {
    state.screenList = data;
  },
  // setCurrentRouteName(state, data) {
  //   state.currentRouteName = data;
  // }
};
const actions = {
  isMobileActions: ({ commit }, data) => {
    commit('setIsMobile', data);
  },
  newDiscountStatusActions: ({ commit }, data) => {
    commit('setNewDiscountStatus', data);
  },
  canvaApiActions: ({ commit }, data) => {
    commit('setCanvaApi', data);
  },
  prevPathActions: ({ commit }, data) => {
    commit('setPrevPath', data);
  },
  setUserInfoActions: ({ commit }, data) => {
    commit('setUserInfo', data);
  },
  screenListActions: ({ commit }, data) => {
    commit('setScreenList', data);
  },
};
// 模块
const modules = {
};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
});
